
import React from 'react'

import Polaris from 'lib/Polaris'

import { NavLink } from 'react-router-dom'

import { parseLink } from './util'

import styles from './pager.module.scss'



const NeighborLink = (props) => {

	const index = ((props.index - 1) % props.pages + props.pages) % props.pages + 1

	return <NavLink exact to={parseLink(index)}>{props.children}</NavLink>
}


const PageLink = (props) => {

	const link = parseLink(props.index)

	if (link === window.location.pathname + window.location.search) {
		return <NavLink exact to={link} className={styles.active}>{props.children}</NavLink>
	} else {
		return <NavLink exact to={link}>{props.children}</NavLink>
	}
}


const NewsPager = (props) => {

	if (!props.pager) return null

	if (props.pager.pages < 2) return null
	
	return (
		<div className={styles.pager}>
		{props.pager.total > 1 && (
			<div>
				<p className={styles.prev}>
					<NeighborLink pages={props.pager.pages} index={props.pager.current-1}>Previous</NeighborLink>
				</p>
				<ol>
					{Polaris.util.sequence(1, props.pager.pages).map((page) => (
					<li key={page}>
						<PageLink index={page}>{page}</PageLink>
					</li>
					))}
				</ol>
				<p className={styles.next}>
					<NeighborLink pages={props.pager.pages} index={props.pager.current+1}>Next</NeighborLink>
				</p>
			</div>
		)}
		</div>
	)
}

export default NewsPager
