
import React from 'react'

import Item from './item'

import styles from './list.module.scss'


const NewsList = (props) => (
	<div className={styles.list}>
	{props && props.news && (
		<ul>
		{props.news.map((item) => (
			<li key={item.path}>
				<Item {...item} />
			</li>
		))}
		</ul>
	)}
	</div>
)

export default NewsList
