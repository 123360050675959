
import {
	HEADER_NAV_SHOW,
	HEADER_NAV_HIDE,
	HEADER_NAV_TOGGLE,
	HEADER_SEARCH_SHOW,
	HEADER_SEARCH_HIDE,
	HEADER_LANGUAGE_SHOW,
	HEADER_LANGUAGE_HIDE
} from './types'

const initialState = {
	visible  : false,
	search   : false,
	language : false
}


export default function headerReducer(state = initialState, action) {

	switch (action.type) {
		case HEADER_NAV_SHOW:
			return {...state, visible:true}

		case HEADER_NAV_HIDE:
			return {...state, visible:false}

		case HEADER_NAV_TOGGLE:
			return {...state, visible:!state.visible}

		case HEADER_SEARCH_SHOW:
			return {...state, search:true}

		case HEADER_SEARCH_HIDE:
			return {...state, search:false}

		case HEADER_LANGUAGE_SHOW:
			return {...state, language:true}

		case HEADER_LANGUAGE_HIDE:
			return {...state, language:false}

		default:
			return state

	}
}