
export default {
	label : 'Take the next step',

	back : {
		link : '/academics/',
		text : '指導教員 一覧へ戻る'
	},

	cols : 2,

	links : [{
		link : '/academics/arts/',
		text : '芸術文化学 （前期・後期）'
	}, {
		link : '/academics/creative-arts/',
		text : '芸術制作 （前期・後期）'
	}, {
		link : '/academics/common/',
		text : '共通科目担当教員 （前期）'
	}]
}