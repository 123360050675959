
import { createStore, applyMiddleware } from 'redux'

import { createBrowserHistory } from 'history'

import { routerMiddleware } from 'connected-react-router'

import createSagaMiddleware from 'redux-saga';

import createRootReducer from './reducers';

import rootSaga from './sagas';


export const history = createBrowserHistory()

export default function configureStore(initialState) {

	const sagaMiddleware = createSagaMiddleware()

	const store = createStore(
		createRootReducer(history),
		initialState,
		applyMiddleware(
			routerMiddleware(history),
			sagaMiddleware
		)
	)

	sagaMiddleware.run(rootSaga);

	return store;
}