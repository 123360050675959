
import util    from '../../shader/util.glsl'
import simplex from '../../shader/simplex.glsl'
import fbm     from '../../shader/fbm.glsl'
import noise   from '../../shader/noise.glsl'

export default `

uniform sampler2D texture;
uniform vec2 resolution;
uniform float time;

varying vec2 vUv;

${util}
${simplex}
${fbm}
${noise}

void main() {

	float u = vUv.x;
	float v = vUv.y;

	u += 0.1 * getNoise3(vec2(u, v), time * 0.1);
	v += 0.1 * getNoise3(vec2(u, v), time * 0.1);

	vec4 color = texture2D(texture, vec2(u, v));

	gl_FragColor = color;
}
`