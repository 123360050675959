
import {
	SCROLL_STATE_UP,
	SCROLL_STATE_DOWN
} from './types'

const initialState = {
	down : false
}


export default function scrollReducer(state = initialState, action) {

	switch (action.type) {
		case SCROLL_STATE_UP:
			return {...state, down:false}

		case SCROLL_STATE_DOWN:
			return {...state, down:true}

		default:
			return state

	}
}