
import React from 'react'

import Slide from 'views/components/common/slide/index'

import Titleblock from './titleblock'

import Contents from './contents'

import styles from './index.module.scss'


const items = [{
	src : '/assets/img/top/top_slide_01.jpg',
	alt : ''
}, {
	src : '/assets/img/top/top_slide_02.jpg',
	alt : ''
}, {
	src : '/assets/img/top/top_slide_04.jpg',
	alt : ''
}, {
	src : '/assets/img/top/top_slide_05.jpg',
	alt : ''
}, {
	src : '/assets/img/top/top_slide_06.jpg',
	alt : ''
}]

const Outline = (props) => (
	<section className={styles.outline}>
		<div>
			<div className={styles.main}>
				<Titleblock />

				<div className={styles.slidewrap}>
					<Slide items={items} />
				</div>
			</div>
			<div className={styles.side}>
				<Contents />
			</div>
		</div>
	</section>
)

export default Outline