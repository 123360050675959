
export default `

float fbm2(vec2 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result /= (1. + 1./2.);
	return result;
}

float fbm2(vec3 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result /= (1. + 1./2.);
	return result;
}

float fbm3(vec2 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result += simplex(v * 4.) / 4.;
	result /= (1. + 1./2. + 1./4.);
	return result;
}

float fbm3(vec3 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result += simplex(v * 4.) / 4.;
	result /= (1. + 1./2. + 1./4.);
	return result;
}

float fbm4(vec2 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result += simplex(v * 4.) / 4.;
	result += simplex(v * 8.) / 8.;
	result /= (1. + 1./2. + 1./4. + 1./8.);
	return result;
}

float fbm4(vec3 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result += simplex(v * 4.) / 4.;
	result += simplex(v * 8.) / 8.;
	result /= (1. + 1./2. + 1./4. + 1./8.);
	return result;
}

float fbm5(vec2 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result += simplex(v * 4.) / 4.;
	result += simplex(v * 8.) / 8.;
	result += simplex(v * 16.) / 16.;
	result /= (1. + 1./2. + 1./4. + 1./8. + 1./16.);
	return result;
}

float fbm5(vec3 v) {
	float result = simplex(v);
	result += simplex(v * 2.) / 2.;
	result += simplex(v * 4.) / 4.;
	result += simplex(v * 8.) / 8.;
	result += simplex(v * 16.) / 16.;
	result /= (1. + 1./2. + 1./4. + 1./8. + 1./16.);
	return result;
}
`