
import {
	FETCH_PAGE,
	FETCH_PAGE_START,
	FETCH_PAGE_PROGRESS,
	FETCH_PAGE_SUCCESS,
	FETCH_PAGE_FAILED,
	FETCH_PAGE_RENDERED
} from './types'


export const fetch = function(path, query, reason) {
	return {type:FETCH_PAGE, path:path, query:query, reason:reason}
}


export const start = function() {
	return {type:FETCH_PAGE_START}
}


export const progress = function(progress) {
	return {type:FETCH_PAGE_PROGRESS, progress:progress}
}


export const success = function(payload) {
	return {type:FETCH_PAGE_SUCCESS, payload:payload}
}


export const failed = function(error) {
	return {type:FETCH_PAGE_FAILED, error:error}
}


export const rendered = function() {
	return {type:FETCH_PAGE_RENDERED}
}


export default {
	fetch    : fetch,
	start    : start,
	progress : progress,
	success  : success,
	failed   : failed,
	rendered : rendered
}