
import React from 'react'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import nl2br from 'react-nl2br'

import ButtonList from 'views/components/common/buttons/list'

import styles from './index.module.scss'


export default function mixPageLink(Component, param) {

	class ComponentWithPageLink extends React.Component {

		shouldComponentUpdate(nextProps) {
			return nextProps.status !== this.props.status && nextProps.status === 'complete'
		}

		render() {
			return (
				<div>
					<Component />

					<section className={styles.pagelink}>
						<div>
							<div className={styles.labelWrap}>
								<h3>{nl2br(param.label)}</h3>

								{param.back && (
									<p className={styles.back}><NavLink exact to={param.back.link}>{nl2br(param.back.text)}</NavLink></p>
								)}
							</div>
							<div className={styles.buttonWrap}>
								<ButtonList cols={param.cols} buttons={param.links} />
							</div>
							{param.back && (
								<p className={styles.back}><NavLink exact to={param.back.link}>{nl2br(param.back.text)}</NavLink></p>
							)}
						</div>
					</section>
				</div>
			)
		}
	}

	function mapStateToProps(state) {
		return {
			status : state.page.status
		}
	}

	return connect(mapStateToProps)(ComponentWithPageLink)
}