
import React from 'react'

import Slider from './slider'

import Select from './select'

import styles from './index.module.scss'


const AnchorLink = (props) => {

	if (!props['has-anchor']) return null

	return (
		<div id="page-anchor" className={styles.anchor}>

			<div className={styles.sliderWrap}>
				<Slider sections={props.sections} />
			</div>

			<div className={styles.selectWrap}>
				<Select sections={props.sections} />
			</div>

		</div>
	)
}

export default AnchorLink