
import React from 'react'

import Button from './button'

import styles from './list.module.scss'


const ButtonList = (props) => (
	<div className={styles.list}>
		<ul data-cols={props.cols}>
			{props.buttons && props.buttons.map((button, i) => (
			<li key={i}>
				<Button {...button} />
			</li>
			))}
		</ul>
	</div>
)

export default ButtonList