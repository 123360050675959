
import React from 'react'

import $ from 'jquery'

import styles from './controller.module.scss'
import Polaris from 'lib/Polaris'


class Controller extends React.Component {

    componentDidMount() {
        this.keyUp = this.keyUp.bind(this)
        this.keyDown = this.keyDown.bind(this)
        $('body').on('keyup', this.keyUp)
        $('body').on('keydown', this.keyDown)
    }

    componentWillUnmount() {
        $('body').off('keyup', this.keyUp)
        $('body').off('keydown', this.keyDown)
    }

    prev() {
        this.props.change(this.props.current - 1)
    }

    next() {
        this.props.change(this.props.current + 1)
    }

    zoom(arg) {
        this.props.zoom(arg);
    }

    keyUp(e) {
        if (e.keyCode === 37) {
            e.preventDefault()
            this.props.change(this.props.current - 1)
        }
        if (e.keyCode === 39) {
            e.preventDefault()
            this.props.change(this.props.current + 1)
        }
    }

    keyDown(e) {
        if (e.keyCode === 38) {
            e.preventDefault()
            this.props.scrollBy(-120)
        }
        if (e.keyCode === 40) {
            e.preventDefault()
            this.props.scrollBy(+120)
        }
    }

    clickSlider(e) {
        if (Polaris.ua.ios && Polaris.ua.safari) {
            const x = this.props.pages * (e.clientX - $(e.target).offset().left) / e.target.clientWidth
            this.props.change(parseInt(x))
        }
    }

    render() {
        return (
            <div className={styles.controller}>
                <div className={styles.key} aria-hidden={this.props.current !== 0}>
                    <p>こちらの論文はキーボードの左右の矢印キーの操作でページを前後に移動することができます</p>
                </div>
                <div className={styles.body}>
                    <div className={styles.seekbar}>
                        <div className={styles.bar}>
                            <div className={styles.black}>
                                <span style={{ width: this.props.current / this.props.pages * 100 + '%' }}></span>
                            </div>
                            <input type="range" value={this.props.current} min="0" max={this.props.pages - 1} step="1" onChange={(e) => this.props.change(e.target.value)} onClick={(e) => this.clickSlider(e)} />
                        </div>
                        <div className={styles.index}>
                            <span>{this.props.current + 1} / {this.props.pages}</span>
                        </div>
                    </div>
                    <button onClick={() => this.prev()} className={styles.prev}><img src="/assets/img/viewer/arrow_l.svg" alt="前のページ" /></button>
                    <button onClick={() => this.next()} className={styles.next}><img src="/assets/img/viewer/arrow_r.svg" alt="次のページ" /></button>
                    <button onClick={() => this.zoom(-1)} className={styles.zoom}><img src="/assets/img/viewer/zoom_minus.svg" alt="縮小する" /></button>
                    <button onClick={() => this.zoom(+1)} className={styles.zoom}><img src="/assets/img/viewer/zoom_plus.svg" alt="拡大する" /></button>
                </div>
            </div>
        )
    }
}

export default Controller