
import React from 'react'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import Logo from './logo'

import NavPC from './nav-pc'

import NavSP from './nav-sp'

import Menu from './menu'

import styles from './index.module.scss'


const Header = (props) => (
	<header id="global-header" className={styles.header} data-scroll-down={props.down}>
		<div className={styles.body}>
			<h1>
				<NavLink exact to="/">
					<Logo color={props.color} />
				</NavLink>
			</h1>

			{props.mode === 'sp' ? (
				<Menu color={props.color} />
			) : (
				<NavPC color={props.color} />
			)}

		</div>

		{props.mode === 'sp' && (
			<NavSP />
		)}
		
	</header>
)

function mapStateToProps(state, props) {
	return {
		mode  : state.device.mode,
		color : state.page.payload['title-color'] === 'white' && !state.scroll.down ? 'white' : 'black',
		down  : state.scroll.down
	}
}

export default connect(mapStateToProps)(Header)