
export const FETCH_PAGE = "FETCH_PAGE"

export const FETCH_PAGE_START = "FETCH_PAGE_START"

export const FETCH_PAGE_PROGRESS = "FETCH_PAGE_PROGRESS"

export const FETCH_PAGE_SUCCESS = "FETCH_PAGE_SUCCESS"

export const FETCH_PAGE_FAILED = "FETCH_PAGE_FAILED"

export const FETCH_PAGE_RENDERED = "FETCH_PAGE_RENDERED"