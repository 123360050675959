
import React from 'react'

import { connect } from 'react-redux'

import fetchPage from 'states/page/api'

import actions from 'states/page/actions'

import styles from './categorynav.module.scss'


class Categorynav extends React.Component {

	state = {
		open    : false,
		current : 'new'
	}

	reload(slug) {
		if (this.state.current === slug) {
			slug = 'all'
		}

		this.setState({
			open    : false,
			current : slug
		})

		this.props.updateNews(slug)
	}

	toggleNav() {
		this.setState({
			open: !this.state.open
		})
	}

	render() {
		return (
			<nav className={styles.categorynav}>
				{this.props.mode === 'sp' && (
					<button onClick={this.toggleNav.bind(this)} aria-pressed={this.state.open}>
						<span></span>FILTER
					</button>
				)}

				<ul aria-expanded={this.state.open}>
					{this.props.category && this.props.category.map((item, i) => (
					<li key={i}>
						<button onClick={(e) => this.reload(item.slug)} className={this.state.current === item.slug ? styles.active : ''}>{item.name}</button>
					</li>
					))}

				</ul>
			</nav>
		)
	}
}

function mapStateToProps(state) {
	return {
		mode : state.device.mode
	}
}

function mapDispatchToProps(dispatch) {
	return {
		updateNews : function(slug) {
			fetchPage('/news/', `?category=${slug}&post_per_page=10`).then((result) => {
				dispatch(actions.success({news:result.payload.news, category:result.payload.category}))
			})
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Categorynav)