
import React from 'react'

import withLoading from 'helpers/withLoading'

import Stage from './stage/index'

import News from './news/index'

import Outline from './outline/index'

import Admission from './admission/index'

import Graduationwork from './graduationwork/'

import styles from './index.module.scss'


const Home = (props) => (
	<div className={styles.home}>

		<Stage />

		<News {...props.payload} />

		<Outline />

		<Admission />

		<Graduationwork />
		
	</div>
)

export default withLoading(Home)