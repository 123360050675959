
import React from 'react'

import withLoading from 'helpers/withLoading'

import Visual from 'views/components/common/visual/index'

import Category from './category'

import List from './list'

import Pager from './pager'

import styles from './index.module.scss'


const NewsList = (props) => (
	<main className={styles.news}>

		<Visual {...props.payload} />

		<Category {...props.payload} />

		<List {...props.payload} />

		<Pager {...props.payload} />

	</main>
)

export default withLoading(NewsList)
