
import React from 'react'

import nl2br from 'react-nl2br'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import Html from './html'

import Download from './download'

import ButtonList from 'views/components/common/buttons/list'

import Viewer from 'views/components/viewer/index'

import styles from './index.module.scss'


const Sections = (props) => {

	return (
		<React.Fragment>

		{props.sections.map((section, i) => (
			<section key={i} className={styles.section} id={section.anchor ? section.anchor.id : ''}>
				<Wrap>
					<Left>
					{section.title && (
						<h3>{nl2br(section.title)}</h3>
					)}
					</Left>
					<Right>
					{section.contents && section.contents.map((content, i) => (
						<React.Fragment key={i}>

						{content.type === 'title' && (
							<div className={styles.title}>
								<h4>{nl2br(content.title)}</h4>
							</div>
						)}

						{content.type === 'text' && (
							<div className={styles.text}>
								<div>{nl2br(content.text)}</div>
							</div>
						)}

						{content.type === 'image' && (
							<div className={styles.image}>
								<img src={content.image.src} alt={content.image.alt} />
							</div>
						)}

						{content.type === 'html' && (
							<div className={styles.text}>
								<Html content={content.html} />
							</div>
						)}

						{content.type === 'button' && (
							<div className={styles.button}>
								<ButtonList cols={content.cols} buttons={content.buttons} />
							</div>
						)}

						{content.type === 'padding' && (
							<div className={styles.padding}>
								<div data-size={content.size} />
							</div>
						)}

						{content.type === 'download' && (
							<div className={styles.download}>
								<Download {...content} />
							</div>
						)}

						{content.type === 'special' && (
							<div className={styles.special}>
								<Viewer {...content} />
							</div>
						)}

						</React.Fragment>
					))}
					</Right>
				</Wrap>
			</section>
		))}

		</React.Fragment>
	)
}

export default Sections