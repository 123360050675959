
import React from 'react'

import $ from 'jquery'

import styles from './pagetop.module.scss';


class Pagetop extends React.Component {

	scroll() {
		$('html,body').stop().animate({scrollTop:0}, 600, 'ioX4')
	}

	render() {
		return (
			<button className={styles.pagetop} onClick={this.scroll.bind(this)}><span></span></button>
		)
	}
}

export default Pagetop