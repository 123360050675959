
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './item.module.scss'


const NewsItem = (props) => (
	<NavLink exact to={props.path} className={styles.item}>
		<div className={styles.thumbnail}>
			{props.thumbnail.src ? (
				<img src={props.thumbnail.src} alt={props.thumbnail.alt} />
			) : (
				<img src="/assets/img/common/default_news_thumbnail.jpg" alt="" />
			)}
		</div>
		<div className={styles.data}>
			<div className={styles.category}>{props.category.map((c) => c.name).join(', ')}</div>
			<div className={styles.title}>{props.title}</div>
			<div className={styles.date}>{props.date}</div>
		</div>
	</NavLink>
)

export default NewsItem
