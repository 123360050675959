
import React from 'react'

import Title from '../common/title'

import LastUpdate from './lastupdate'

import CategoryNav from './categorynav'

import NewsSlide from './slide'

import styles from './index.module.scss'


const News = (props) => (
	<section className={styles.news}>

		<Title text="News" more={{path:'/news/', text:'ニュースをもっと見る'}} />

		<div className={styles.infoblock}>
			<LastUpdate {...props} />
			<CategoryNav {...props} />
		</div>

		<NewsSlide {...props} />

	</section>
)

export default News