
export default `

const int noiseSwirlSteps = 2;
const float noiseSwirlValue = 1.;
const float noiseSwirlStepValue = noiseSwirlValue / float(noiseSwirlSteps);


float getNoise(vec3 v) {
	//  make it curl
	for (int i=0; i<noiseSwirlSteps; i++) {
		v.xy += vec2(fbm3(v), fbm3(vec3(v.xy, v.z + 1000.))) * noiseSwirlStepValue;
	}
	//  normalize
	return fbm5(v) / 2. + 0.5;
}

float getNoise2(vec3 v) {
	float z = 0.0;

	for (int i=0; i<2; i++) {
		z += simplex(v);
		v = vec3(z, simplex(vec3(z, z, z + 100.0)), z);
	}
	return z;
}

float getNoise3(vec3 v) {
	for (int i=0; i<1; i++) {
		v.xy += vec2(fbm3(v), fbm3(vec3(v.xy, v.z + 100.0)));
	}
	return fbm5(v);
}

float getNoise3(vec2 v, float time) {
	float z = fbm3(vec3(v, time * 0.5));
	v += vec2(z, z + 10.0);
	return fbm4(v);
}

float getNoise8(vec2 v, float time) {
	float z1 = fbm4(v);
	v.xy += vec2(z1 + 3.0 * cos(time), z1 + 3.0 * sin(time) + 100.0);
	return fbm4(v);
}

`