
import React from 'react'

import { NavLink } from 'react-router-dom'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import styles from './index.module.scss'


const Related = (props) => (
	<React.Fragment>
	{props.related && (
		<section className={styles.related}>
			<Wrap>
				<Left>
					<h3>Related content</h3>
				</Left>
				<Right>
					<ul>
					{props.related.map((item, i) => (
						<li key={i}><NavLink exact to={item.path}>{item.title}</NavLink></li>
					))}
					</ul>
				</Right>
			</Wrap>
		</section>
	)}
	</React.Fragment>
)

export default Related