
import React from 'react'

import Dates from './dates'

import styles from './calendar.module.scss'


const Calendar = (props) => {

	if (!props.calendar) return null

	const semester1 = []
	const semester2 = []

	props.calendar.semester1.forEach((row) => {
		row.month in semester1 ? semester1[row.month]++ : semester1[row.month] = 1
	})

	props.calendar.semester2.forEach((row) => {
		row.month in semester2 ? semester2[row.month]++ : semester2[row.month] = 1
	})

	let month1 = -1
	let month2 = -1

	return (
		<table className={styles.calendar}>
			<thead>
				<tr>
					<th>学期</th><th>月</th><th>日（曜日）</th><th>行事</th>
				</tr>
			</thead>
			<tbody>
				{props.calendar.semester1.map((row, i) => {
					const showMonth = row.month !== month1
					month1 = row.month

					return <tr key={i}>
						{i === 0 && (
						<td rowSpan={props.calendar.semester1.length}>前期</td>
						)}
						
						{showMonth && (
						<td rowSpan={semester1[row.month]}>{row.month}月</td>
						)}

						<td><Dates dates={row.dates} /></td>
						<td dangerouslySetInnerHTML={{__html:row.event}}></td>
					</tr>
				})}

				{props.calendar.semester2.map((row, i) => {
					const showMonth = row.month !== month2
					month2 = row.month

					return <tr key={i}>
						{i === 0 && (
						<td rowSpan={props.calendar.semester2.length}>後期</td>
						)}
						
						{showMonth && (
						<td rowSpan={semester2[row.month]}>{row.month}月</td>
						)}

						<td><Dates dates={row.dates} /></td>
						<td dangerouslySetInnerHTML={{__html:row.event}}></td>
					</tr>
				})}
			</tbody>
		</table>
	)
}

export default Calendar
