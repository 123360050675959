
import React from 'react';

import { NavLink } from 'react-router-dom'

import Language from 'views/components/common/language/index'

import SearchButton from 'views/components/common/search/button'

import Fnav from './fnav'

import Groupnav from './groupnav'

import styles from './fbottom.module.scss';


const Fbottom = (props) => (
	<div className={styles.fbottom}>
		<div className={styles.wrap}>
			<div className={styles.left}>

				<div className={styles.logo}>
					<NavLink exact to="/"><img src="/assets/img/common/logo_color.svg" alt="" /></NavLink>
				</div>

				<div className={styles.flex}>
					<div className={styles.language}>
						<Language color="gray" />
					</div>
					<div className={styles.search}>
						<SearchButton color="black" />
					</div>
				</div>
			</div>
			<div className={styles.right}>

				<Fnav />

				<Groupnav />

				<address className={styles.info}>
					<span>大阪芸術大学 大学院</span><span>〒585-8555 大阪府南河内郡河南町東山469</span><a href="tel:0721933781">0721-93-3781（代）</a>
				</address>

				<p className={styles.copyright}>Copyright © 2019 Osaka University of Arts Graduate School.</p>
			</div>
		</div>
	</div>
)

export default Fbottom