
import React from 'react'

import { NavLink } from 'react-router-dom'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import styles from './index.module.scss'


const Pager = (props) => (
	<div className={styles.pager}>
		<Wrap>
			<Left>
				<h3>Explore Further</h3>
				<p className={styles.backbtn}><NavLink exact to={props.parent.path}>{props.parent.name} 一覧へ戻る</NavLink></p>
			</Left>
			<Right>
				<ul>
					<li className={styles.prev}>{props.prev && (<NavLink exact to={props.prev}>Previous</NavLink>)}</li>
					<li className={styles.next}>{props.next && (<NavLink exact to={props.next}>Next</NavLink>)}</li>
				</ul>
				<p className={styles.backbtn}><NavLink exact to={props.parent.path}>{props.parent.name} 一覧へ戻る</NavLink></p>
			</Right>
		</Wrap>
	</div>
)

export default Pager
