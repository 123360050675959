
import React from 'react'

import { NavLink } from 'react-router-dom'

import { connect } from 'react-redux'

import actions from 'states/header/actions'

import styles from './language-panel.module.scss'


const LanguagePanel = (props) => (
	<div className={styles.panel} aria-expanded={props.show}>
		<div className={styles.header}>
			<button onClick={props.hidePanel}></button>
		</div>
		<div className={styles.body}>
			<ul>
				<li><NavLink className={props.lang === 'ja' ? styles.current : ''} exact to="/">日本語</NavLink></li>
				<li><NavLink className={props.lang === 'en' ? styles.current : ''} exact to="/en-us/">English</NavLink></li>
			</ul>
		</div>
	</div>
)

function mapStateToProps(state, props) {
	return {
		lang : state.page.lang,
		show : state.header.language
	}
}

function mapDispatchToProps(dispatch) {
	return {
		hidePanel : () => dispatch(actions.hideLanguage())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePanel)
