
import React from 'react';

import { connect } from 'react-redux'

import { CSSTransition } from 'react-transition-group'

import styles from './index.module.scss';


const timeout = {
	appear : 20,
	enter  : 300,
	exit   : 500 + 500
}

const Loading = (props) => {

	const barStyle = {
		width : `${props.progress * 100}%`,
		transition : props.progress === 0 ? 'none' : ''
	}

	return (
		<CSSTransition in={props.visible} timeout={timeout} mountOnEnter={true} unmountOnExit={true} classNames={{...styles}}>
			<div className={styles.loading}>
				<div className={styles.bar}>
					<span style={barStyle}></span>
				</div>
			</div>
		</CSSTransition>
	)
}

function mapStateToProps(state) {
	return {
		first    : state.page.first,
		visible  : !state.page.first && state.page.status !== 'complete',
		status   : state.page.status,
		progress : state.page.progress
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Loading)