
import React from 'react';

import { NavLink } from 'react-router-dom'

import styles from './groupnav.module.scss';


const Groupnav = (props) => (
	<div className={styles.groupnav}>
		<nav>
			<ul>
				<li><a href="https://www.osaka-geidai.ac.jp/" target="_blank" rel="noopener noreferrer">芸術学部</a></li>
				<li><NavLink exact to="/">大学院</NavLink></li>
				<li><a href="http://www.cord.osaka-geidai.ac.jp/" target="_blank" rel="noopener noreferrer">通信教育部</a></li>
				<li><a href="http://osaka-geitan.jp/" target="_blank" rel="noopener noreferrer">短期大学部</a></li>
				<li><a href="https://www.bisen.ac.jp/" target="_blank" rel="noopener noreferrer">大阪美術専門学校</a></li>
				<li><a href="http://www.kids.osaka-geidai.ac.jp/" target="_blank" rel="noopener noreferrer">附属幼稚園</a></li>
				<li><NavLink exact to="/policy/">サイトのご利用にあたって</NavLink></li>
				<li><a href="https://www.osaka-geidai.ac.jp/geidai/site/privacypolicy.html" target="_blank" rel="noopener noreferrer">個人情報保護方針</a></li>
				<li><a href="https://www.osaka-geidai.ac.jp/geidai/site/teacher.html" target="_blank" rel="noopener noreferrer">教職員の方へ</a></li>
				<li><a href="https://webmail.osaka-geidai.ac.jp/am_bin/amlogin" target="_blank" rel="noopener noreferrer">メールログイン</a></li>
			</ul>
		</nav>
	</div>
)

export default Groupnav