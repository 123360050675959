
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/header/actions'

import Form from 'views/components/common/search/form'

import styles from './search-panel.module.scss'


const SearchPanel = (props) => (
	<div className={styles.panel} aria-expanded={props.show}>
		<div className={styles.header}>
			<button onClick={props.hidePanel}></button>
		</div>
		<div className={styles.body}>
			<Form afterSubmit={props.hidePanel} />
		</div>
	</div>
)

function mapStateToProps(state, props) {
	return {
		show : state.header.search
	}
}

function mapDispatchToProps(dispatch) {
	return {
		hidePanel : () => dispatch(actions.hideSearch())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPanel)
