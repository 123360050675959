
import React from 'react'

import styles from './control.module.scss'


class Control extends React.Component {

	state = {
		dotSize        : 0,
		distance       : 0,
		bloomStrength  : 0,
		bloomRadius    : 0,
		bloomThreshold : 0,
	}

	componentDidMount() {

		this.setState({
			dotSize        : this.props.brain.dotSize,
			distance       : this.props.brain.distance,
			bloomStrength  : this.props.brain.bloomPass.strength,
			bloomRadius    : this.props.brain.bloomPass.radius,
			bloomThreshold : this.props.brain.bloomPass.threshold,
		})
	}

	componentDidUpdate() {
		this.props.brain.setDotSize(this.state.dotSize)
		this.props.brain.setDistance(this.state.distance)
		this.props.brain.setBloomParam(this.state.bloomStrength, this.state.bloomRadius, this.state.bloomThreshold)
	}

	changeDotSize(e) {
		this.setState({
			dotSize : e.target.value
		})
	}

	changeDistance(e) {
		this.setState({
			distance : e.target.value
		})
	}

	changeBloomStrength(e) {
		this.setState({
			bloomStrength : e.target.value
		})
	}

	changeBloomRadius(e) {
		this.setState({
			bloomRadius : e.target.value
		})
	}

	changeBloomThreshold(e) {
		this.setState({
			bloomThreshold : e.target.value
		})
	}

	render() {
		return (
			<table className={styles.control}>
				<tbody>
					<tr>
						<th>dotSize</th>
						<td><input type="range" step="0.01" min="0.01" max="10"
							onChange={this.changeDotSize.bind(this)}
							value={this.state.dotSize} /></td>
						<td>{this.state.dotSize}</td>
					</tr>
					<tr>
						<th>distance</th>
						<td><input type="range" step="0.1" min="1" max="10"
							onChange={this.changeDistance.bind(this)}
							value={this.state.distance} /></td>
						<td>{this.state.distance}</td>
					</tr>
					<tr>
						<th>bloomStrength</th>
						<td><input type="range" step="0.01" min="0" max="3"
							onChange={this.changeBloomStrength.bind(this)}
							value={this.state.bloomStrength} /></td>
						<td>{this.state.bloomStrength}</td>
					</tr>
					<tr>
						<th>bloomRadius</th>
						<td><input type="range" step="0.01" min="0" max="3"
							onChange={this.changeBloomRadius.bind(this)}
							value={this.state.bloomRadius} /></td>
						<td>{this.state.bloomRadius}</td>
					</tr>
					<tr>
						<th>bloomThreshold</th>
						<td><input type="range" step="0.01" min="0" max="1"
							onChange={this.changeBloomThreshold.bind(this)}
							value={this.state.bloomThreshold} /></td>
						<td>{this.state.bloomThreshold}</td>
					</tr>
				</tbody>
			</table>
		)
	}
}

export default Control