
import {
	FETCH_PAGE,
	FETCH_PAGE_START,
	FETCH_PAGE_PROGRESS,
	FETCH_PAGE_SUCCESS,
	FETCH_PAGE_FAILED,
	FETCH_PAGE_RENDERED
} from './types'


const initialState = {
	first   : true,
	lang    : 'ja',
	path    : null,
	query   : null,
	status  : 'complete',
	payload : {},
	error   : null,
	reason  : null,
	rendered : false
}

function getLang(path) {
	if (path) {
		if (path.indexOf('/en-us/') === 0) {
			return 'en'
		} else if (path.indexOf('/zh-cn/') === 0) {
			return 'zh-cmn-Hans'
		} else if (path.indexOf('/zh-tw/') === 0) {
			return 'zh-cmn-Hant'
		} else if (path.indexOf('/ko-kr/') === 0) {
			return 'ko'
		}
	}
	return 'ja'
}

export default function pageReducer(state = initialState, action) {

	switch (action.type) {
		case FETCH_PAGE:
			return {...state, path:action.path, query:action.query, error:null, reason:action.reason, rendered:false}

		case FETCH_PAGE_START:
			return {...state, status:'loading'}

		case FETCH_PAGE_PROGRESS:
			return {...state, status:'loading', progress:action.progress}

		case FETCH_PAGE_SUCCESS:
			return {...state, first:false, status:'complete', payload:action.payload}

		case FETCH_PAGE_FAILED:
			return {...state, first:false, status:'complete', payload:{}, error:action.error}

		case FETCH_PAGE_RENDERED:
			return {...state, lang:getLang(state.path), rendered:true}

		default:
			return state

	}
}