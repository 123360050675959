
import React from 'react'

import withLoading from 'helpers/withLoading'

import Visual from 'views/components/common/visual/index'

import FacilityMap from './map'

import FacilitySelect from './select'

import FacilityItems from './items'


const Facility = (props) => (
	<main>
		<Visual {...props.payload} />

		{props.payload.facilities && (
		<React.Fragment>

			<FacilityMap items={props.payload.facilities} />

			<FacilitySelect items={props.payload.facilities} />

			<FacilityItems items={props.payload.facilities} />
			
		</React.Fragment>
		)}

	</main>
)

export default withLoading(Facility)
