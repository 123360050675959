
import Polaris from 'lib/Polaris'

function parseLink(index=1) {
	const params = []
	const request = Polaris.util.parseParam()

	params.push(['query', request.query])

	// if (true) {
	// 	params.push(['post_per_page', 2])
	// }

	if (index !== 1) {
		params.push(['current_page', index])
	}

	const query = params.map((tuple) => tuple[0] + '=' + tuple[1]).join('&')

	if (query) {
		return '/search/?' + query
	} else {
		return '/search/'
	}
}

export {
	parseLink
}