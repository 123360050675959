
import React from 'react'

import withLoading from 'helpers/withLoading'

import Visual from 'views/components/common/visual/index'

import Anchor from 'views/components/common/anchor/index'

import Related from 'views/components/common/related/index'

import Section from './section'

import styles from './index.module.scss'


const ProfessorList = (props) => {

	if (!props.payload || !props.payload.sections) return null

	return (
		<main className={styles.professor}>

			<Visual {...props.payload} />

			<Anchor {...props.payload} />

			{props.payload.sections.map((section, i) => (
			<Section key={i} {...section} />
			))}

			<Related {...props.payload} />

		</main>
	)
}

export default withLoading(ProfessorList)
