
export default {
	label : 'Explore Further',

	cols : 3,

	links : [{
		link : '/dept/message/',
		text : '学長・研究科長挨拶'
	}, {
		link : '/dept/curriculum/',
		text : '教育課程編成・実施の方針\n（カリキュラムポリシー）'
	}, {
		link : '/dept/diploma/',
		text : '学位授与の方針\n（ディプロマポリシー）'
	}, {
		link : '/dept/org-faculty/',
		text : '大学院の構成'
	}, {
		link : '/dept/teacher-message/',
		text : '教員からのメッセージ'
	}, {
		link : '/dept/graduate/',
		text : '社会で活躍する修了生'
	}, {
		link : '/dept/facilities/',
		text : 'キャンパスと施設'
	}, {
		link : 'https://www.osaka-geidai.ac.jp/geidai/news/geitsu/index.html',
		text : '芸大通信'
	}, {
		link : 'https://www.osaka-geidai.ac.jp/koyukai/index.html',
		text : '塚本学院校友会'
	}]
}