
import {
	SCROLL_STATE_UP,
	SCROLL_STATE_DOWN
} from './types'


export const scrollUp = function() {
	return {type:SCROLL_STATE_UP}
}

export const scrollDown = function() {
	return {type:SCROLL_STATE_DOWN}
}

export default {
	scrollUp   : scrollUp,
	scrollDown : scrollDown,
}