
import React from 'react'

import styles from './logo.module.scss'

export default (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 418 68">
		<title>大阪芸術大学 大学院　Osaka University of Arts Graduate School</title>
		<g className={styles[props.color]}>
		<path className={styles.logo} d="M34.22,0c19.25-.06,34.05,14.78,34.2,34,.16,18.78-15.31,34-34.2,34A34.11,34.11,0,0,1,0,34C0,15.66,15.78.05,34.22,0m-8,7.93c-3.45.65-5.59,1.78-5.59,3,0,.58.86,1.82,5.88,2.29.46,1.91,1.29,3.06,2.18,3.06s1.65-1.09,2.15-2.86c1,0,2.09,0,3.28-.06,1.45,0,2.75-.11,3.93-.2.47,1.93,1.34,3.12,2.21,3.12s1.88-1.36,2.3-3.61c4-.62,5.37-1.51,5.37-2.29,0-1.34-2.11-2.11-5.21-2.56-.26-3-1.11-5.51-2.46-5.51-1,0-2.08,2.25-2.44,5.1-1.45-.05-3-.08-4.48-.08-.76,0-1.49,0-2.21,0-.37-2.84-1.4-5.07-2.44-5.07-1.38,0-2.22,2.55-2.47,5.63M44.57,19.86c0-2.21-5.43-3.2-10.84-3-5.8.24-9.27,1.78-9.25,2.89,0,.62,1,2.82,10.06,2.91,5.51.06,10-1.49,10-2.81M49.73,49.3c.19-1.92-3.91-2.63-9.21-3.07-2.13-3.29-4.33-6.07-5.67-6.4a.74.74,0,0,0-.25,0,.59.59,0,0,0-.23,0c-1.39.33-3.68,3.28-5.88,6.72-6.81.87-8.74,1.12-8.65,3.21,0,.55,1.12,1.82,5.35,2.55-2.45,5-4.51,11.2-3.26,12,1.51.9,5.51-3.34,8.83-8.41.67-1.05,1.28-2,1.81-3,1,0,2.09,0,3.25,0l1.25-.06c.52.88,1.1,1.83,1.75,2.82,3.3,5.08,6.88,9,8.43,8.13S46.53,57.07,44,52.19c4.69-.7,5.6-1.8,5.72-2.89m2.35-22.24c.09-2.49-9.4-3.34-19-3-8.61.26-16,1-16,3.2,0,.65,1,2.19,9.74,2.53-2.47,3.51-4.58,7.06,1.19,7.79,6.71.84,8,.74,11.39.32s6.39-.64,5.35-4.19a37.57,37.57,0,0,0-2.55-4.13C49.65,29.3,52,28.93,52.08,27.06ZM34.39,30.39c-1.87,0-3.36.26-3.42,1.48s1.62,1.48,3.49,1.48,3.7-.38,3.7-1.53C38.16,30.44,36.25,30.39,34.39,30.39Z"/>
		<path className={styles.text} d="M78.64,20.55V17.19H91.83V10.1H95.7v7.09h13.18v3.36h-13A20.78,20.78,0,0,0,100,31.08a26.07,26.07,0,0,0,9.48,7.48l-2,2.9a25.6,25.6,0,0,1-8.13-6,27.56,27.56,0,0,1-5.45-8.66,26.62,26.62,0,0,1-5.54,8.66,26.13,26.13,0,0,1-8.32,6l-2-2.9a26,26,0,0,0,9.48-7.48,20.88,20.88,0,0,0,4.17-10.53Z"/>
		<path className={styles.text} d="M125.41,29.41a7.39,7.39,0,0,0-.65-3.13,17.85,17.85,0,0,0-2.48-3.6,41.35,41.35,0,0,0,4-8.75H121.9V41.82h-3.52V11h11.25v2.94a48.4,48.4,0,0,1-3.73,8.57,12.48,12.48,0,0,1,2.85,7.73,5.31,5.31,0,0,1-1.19,3.83,5.08,5.08,0,0,1-3.73,1.2h-.71l-.59-3h.7a2.14,2.14,0,0,0,1.73-.57A3.93,3.93,0,0,0,125.41,29.41ZM149,11.16v3.19H134V19H148.2v3.12a31.25,31.25,0,0,1-5.31,11.62,21.71,21.71,0,0,0,7,4.86l-1.76,3.08a24.68,24.68,0,0,1-7.59-5.28,26.17,26.17,0,0,1-8.44,5.28l-1.44-2.94a18.34,18.34,0,0,1-1.8,2.76l-2.49-3.19a20.52,20.52,0,0,0,3-6.92,42.61,42.61,0,0,0,1-10V11.16ZM138.21,33.6A31.24,31.24,0,0,1,134,25.2a29.74,29.74,0,0,1-3.2,13.18A23,23,0,0,0,138.21,33.6Zm2.39-2.8a26.49,26.49,0,0,0,3.84-8.72H134v1.66L137,22.61A30.76,30.76,0,0,0,140.6,30.8Z"/>
		<path className={styles.text} d="M156.89,12.76h6.86V9.57h3.69v3.19h10.2V9.57h3.69v3.19h6.86v3.08h-6.86v2.84h-3.69V15.84h-10.2v2.84h-3.69V15.84h-6.86Zm25.28,17.61q2.78,4.79,5.63,10.28l-3.27,1.45-.83-1.58-.89-1.72a227,227,0,0,1-24.16,2.13l-.18-3.19,1.55-.07,1.79-.07,1.55-.07q1.54-2.91,3.55-7.76h-9.67V26.58h30.6v3.19H171c-.84,2.19-2,4.68-3.34,7.44q6.61-.47,13.57-1.35c-.68-1.25-1.43-2.59-2.25-4Zm-21.94-6.8V20.45h24.62v3.12Z"/>
		<path className={styles.text} d="M204.62,17.12h5.17V10.38h3.7v6.74h3.58a48.33,48.33,0,0,0-3.34-5l2.61-1.7A46.06,46.06,0,0,1,220,15.84l-2.18,1.28h1v2.12h9.78V22.5h-2.46V36.39c0,2.32-.26,3.76-.78,4.33s-1.81.85-3.9.85c-.77,0-2.12-.07-4-.21l-.18-3.26c1.5.11,2.71.17,3.62.17s1.23-.08,1.36-.24a3.77,3.77,0,0,0,.19-1.64V22.5h-4.12V20.31h-4.74v21.4h-3.7V20.31h-4.85a30.61,30.61,0,0,1-3.24,4.74V42h-3.62V28.17a18.57,18.57,0,0,1-2.53,2.45l-1-3.76A30.28,30.28,0,0,0,199,22.79a34.88,34.88,0,0,0,3.62-5.07l2,1.28Zm-8.37,2.8L195,16.69a26.17,26.17,0,0,0,7.6-7l2.57,2a32,32,0,0,1-4.13,4.73A29.59,29.59,0,0,1,196.25,19.92Zm6.3,17.64a93,93,0,0,0,2.57-15.2l3.27.43a95.49,95.49,0,0,1-2.57,15.7ZM214.3,22.93l3.27-.89a113,113,0,0,1,2.7,13.86l-3.27.64A101.33,101.33,0,0,0,214.3,22.93Zm5.1-8.51V11.16H228v3.26Z"/>
		<path className={styles.text} d="M235.14,20.55V17.19h13.19V10.1h3.87v7.09h13.19v3.36h-13a20.79,20.79,0,0,0,4.17,10.53A25.91,25.91,0,0,0,266,38.56l-2,2.9a25.56,25.56,0,0,1-8.12-6,27.39,27.39,0,0,1-5.45-8.66,27,27,0,0,1-5.54,8.66,26.24,26.24,0,0,1-8.32,6l-2-2.9A26,26,0,0,0,244,31.08a20.7,20.7,0,0,0,4.17-10.53Z"/>
		<path className={styles.text} d="M278.43,23.14V20h19.13v3.19a42,42,0,0,1-6.65,6H304v3.26H290.46v5.21c0,1.86-.26,3-.76,3.43s-1.87.64-4.1.64c-1.33,0-3.21-.05-5.62-.14l-.15-3.3c2.77.12,4.55.18,5.35.18a2.3,2.3,0,0,0,1.23-.18q.18-.17.18-1.17V32.43H272V29.17h14.6V26.33h2.32q2-1.56,3.8-3.19Zm20.75-5.67H276.81v5.67h-3.59V14.35H278c-.59-1.06-1.2-2.12-1.83-3.19l3.31-1.34a48.09,48.09,0,0,1,2.49,4.53h4.33c-.77-1.51-1.42-2.69-1.93-3.54l3.27-1.35a52.57,52.57,0,0,1,2.6,4.89H294a46.52,46.52,0,0,0,2.61-4.68l3.37,1c-.7,1.42-1.37,2.66-2,3.72h4.74v8.79h-3.58Z"/>
		<path className={styles.text} d="M310.6,20.55V17.19h13.19V10.1h3.87v7.09h13.19v3.36H327.8A20.78,20.78,0,0,0,332,31.08a26.07,26.07,0,0,0,9.48,7.48l-2,2.9a25.6,25.6,0,0,1-8.13-6,27.75,27.75,0,0,1-5.45-8.66,26.62,26.62,0,0,1-5.54,8.66,26.2,26.2,0,0,1-8.31,6l-2-2.9a25.91,25.91,0,0,0,9.48-7.48,20.88,20.88,0,0,0,4.17-10.53Z"/>
		<path className={styles.text} d="M354.45,23.14V20h19.13v3.19a42,42,0,0,1-6.64,6H380v3.26H366.48v5.21c0,1.86-.25,3-.76,3.43s-1.86.64-4.09.64c-1.34,0-3.22-.05-5.63-.14l-.14-3.3c2.77.12,4.55.18,5.35.18a2.3,2.3,0,0,0,1.23-.18,2.22,2.22,0,0,0,.17-1.17V32.43H348V29.17h14.59V26.33h2.32q2-1.56,3.8-3.19Zm20.75-5.67H352.84v5.67h-3.59V14.35H354c-.59-1.06-1.2-2.12-1.83-3.19l3.3-1.34a48,48,0,0,1,2.5,4.53h4.33c-.78-1.51-1.42-2.69-1.94-3.54l3.27-1.35a55,55,0,0,1,2.61,4.89h3.83a44.55,44.55,0,0,0,2.6-4.68l3.38,1c-.71,1.42-1.37,2.66-2,3.72h4.75v8.79H375.2Z"/>
		<path className={styles.text} d="M408.47,13.11h8.65v7.44h-2.67v1.53H399.89V20.55h-2.57V15.42a51,51,0,0,1-3.2,7.08A12.48,12.48,0,0,1,397,30.23a5.31,5.31,0,0,1-1.19,3.83,5.08,5.08,0,0,1-3.73,1.2h-.71l-.59-3h.7a2.11,2.11,0,0,0,1.72-.57,3.83,3.83,0,0,0,.46-2.27,7.39,7.39,0,0,0-.65-3.13,17.85,17.85,0,0,0-2.48-3.6,41.32,41.32,0,0,0,4-8.75h-4.36V41.82H386.6V11h11.25v2.12h6.75V9.57h3.87Zm6,19.25,3.38.35c-.05,1.23-.09,2.17-.11,2.83a20.2,20.2,0,0,1-.19,2.1c-.11.73-.19,1.25-.25,1.57a2.58,2.58,0,0,1-.46,1,2.11,2.11,0,0,1-.63.68,5,5,0,0,1-1,.3,6.11,6.11,0,0,1-1.25.16h-1.7c-2.3,0-3.74-.18-4.33-.52s-.87-1.16-.87-2.46V28.53h-1.83q-.15,5.24-2.31,8.22t-7.33,5.07l-1.93-2.91Q398,37.24,399.78,35a10.86,10.86,0,0,0,1.9-6.47h-4.36V25.34h19.8v3.19h-6.33V37c0,.57.07.9.21,1a4,4,0,0,0,1.45.14h0q1.15,0,1.44-.36c.26-.35.42-1.65.49-3.9,0-.26,0-.67,0-1.24ZM401,19h12.44V16.2H401Z"/>
		<path className={styles.text} d="M78.44,58.31A6.7,6.7,0,0,1,78.22,56a6.77,6.77,0,0,1,.22-2.28,3.31,3.31,0,0,1,6.08,0A6.77,6.77,0,0,1,84.74,56a6.7,6.7,0,0,1-.22,2.28,3.31,3.31,0,0,1-6.08,0Zm4.64-.37A6.55,6.55,0,0,0,83.23,56a6.42,6.42,0,0,0-.15-1.9,1.73,1.73,0,0,0-3.2,0,6.42,6.42,0,0,0-.15,1.9,6.55,6.55,0,0,0,.15,1.91,1.73,1.73,0,0,0,3.2,0Z"/>
		<path className={styles.text} d="M87.71,59.22a.13.13,0,0,1,0-.19l.78-.87a.11.11,0,0,1,.18,0A3.92,3.92,0,0,0,91,59c1,0,1.61-.5,1.61-1.19s-.38-1-1.56-1.15l-.46-.06c-1.65-.22-2.59-1-2.59-2.37s1.14-2.5,2.91-2.5a4.87,4.87,0,0,1,2.78.83.11.11,0,0,1,0,.17l-.6.91a.13.13,0,0,1-.18,0,3.71,3.71,0,0,0-2.06-.64c-.87,0-1.35.46-1.35,1.11s.41,1,1.58,1.12l.45.06c1.66.22,2.56,1,2.56,2.4S93,60.31,90.86,60.31A4.91,4.91,0,0,1,87.71,59.22Z"/>
		<path className={styles.text} d="M99.59,52a.17.17,0,0,1,.17-.12h1.16a.15.15,0,0,1,.16.12L104,60.06c0,.07,0,.12-.09.12h-1.24a.15.15,0,0,1-.16-.12l-.57-1.61H98.7l-.57,1.61a.16.16,0,0,1-.17.12H96.74c-.09,0-.11,0-.09-.12Zm1.89,5.16L100.34,54h0l-1.15,3.22Z"/>
		<path className={styles.text} d="M106.68,52a.12.12,0,0,1,.13-.12h1.24a.12.12,0,0,1,.12.12v3.9L111.42,52a.21.21,0,0,1,.2-.1h1.44c.08,0,.12.08,0,.16l-2.66,3.09,3,4.89c.06.09,0,.15-.08.15h-1.41a.18.18,0,0,1-.18-.1l-2.25-3.83-1.32,1.54v2.27a.12.12,0,0,1-.12.12h-1.24a.12.12,0,0,1-.13-.12Z"/>
		<path className={styles.text} d="M118.42,52a.17.17,0,0,1,.17-.12h1.16a.15.15,0,0,1,.16.12l2.91,8.05c0,.07,0,.12-.09.12h-1.24a.15.15,0,0,1-.16-.12l-.57-1.61h-3.24L117,60.06a.16.16,0,0,1-.17.12h-1.22c-.09,0-.12,0-.09-.12Zm1.88,5.16L119.17,54h0L118,57.17Z"/>
		<path className={styles.text} d="M137.46,57.24V52a.12.12,0,0,1,.12-.12h1.24A.12.12,0,0,1,139,52v5.26a3.17,3.17,0,0,1-6.33,0V52a.12.12,0,0,1,.13-.12H134a.12.12,0,0,1,.13.12v5.23a1.68,1.68,0,1,0,3.35,0Z"/>
		<path className={styles.text} d="M142.56,52a.12.12,0,0,1,.13-.12h1.16a.23.23,0,0,1,.22.12l3.49,5.72h.05V52a.12.12,0,0,1,.13-.12h1.1A.12.12,0,0,1,149,52v8.05a.12.12,0,0,1-.13.12h-1.15a.22.22,0,0,1-.21-.12L144,54.35h-.05v5.71a.12.12,0,0,1-.12.12h-1.1a.12.12,0,0,1-.13-.12Z"/>
		<path className={styles.text} d="M152.63,52a.12.12,0,0,1,.13-.12H154a.12.12,0,0,1,.12.12v8.05a.12.12,0,0,1-.12.12h-1.24a.12.12,0,0,1-.13-.12Z"/>
		<path className={styles.text} d="M159.88,60.18a.17.17,0,0,1-.15-.12L157,52c0-.07,0-.12.1-.12h1.22a.15.15,0,0,1,.17.12l1.92,5.88h0L162.31,52a.14.14,0,0,1,.16-.12h1.23c.07,0,.13,0,.1.12l-2.73,8.05a.15.15,0,0,1-.15.12Z"/>
		<path className={styles.text} d="M166.7,52a.12.12,0,0,1,.12-.12h5.38a.12.12,0,0,1,.13.12v1.05a.12.12,0,0,1-.13.12h-3.94s-.07,0-.07.07v2s0,.07.07.07h3.32a.12.12,0,0,1,.13.12v1.05a.12.12,0,0,1-.13.12h-3.32s-.07,0-.07.08v2.09s0,.08.07.08h3.94a.12.12,0,0,1,.13.12v1.05a.12.12,0,0,1-.13.12h-5.38a.12.12,0,0,1-.12-.12Z"/>
		<path className={styles.text} d="M180.54,60.18a.19.19,0,0,1-.19-.12l-1.64-3.23h-1.48s-.07,0-.07.07v3.16a.12.12,0,0,1-.13.12h-1.24a.12.12,0,0,1-.12-.12V52a.12.12,0,0,1,.12-.12h3.33a2.52,2.52,0,0,1,2.74,2.47,2.36,2.36,0,0,1-1.63,2.28L182,60a.1.1,0,0,1-.09.16Zm-.16-5.82a1.18,1.18,0,0,0-1.31-1.18h-1.84s-.07,0-.07.07v2.21s0,.07.07.07h1.84A1.18,1.18,0,0,0,180.38,54.36Z"/>
		<path className={styles.text} d="M184.41,59.22a.14.14,0,0,1,0-.19l.79-.87a.1.1,0,0,1,.17,0,3.92,3.92,0,0,0,2.34.88c1,0,1.62-.5,1.62-1.19s-.38-1-1.57-1.15l-.45-.06c-1.66-.22-2.59-1-2.59-2.37s1.13-2.5,2.9-2.5a4.82,4.82,0,0,1,2.78.83c.06.05.08.1,0,.17l-.61.91a.13.13,0,0,1-.18,0,3.69,3.69,0,0,0-2.06-.64c-.87,0-1.35.46-1.35,1.11s.42,1,1.58,1.12l.46.06c1.65.22,2.56,1,2.56,2.4s-1.1,2.57-3.23,2.57A4.94,4.94,0,0,1,184.41,59.22Z"/>
		<path className={styles.text} d="M194.08,52a.12.12,0,0,1,.12-.12h1.24a.12.12,0,0,1,.13.12v8.05a.12.12,0,0,1-.13.12H194.2a.12.12,0,0,1-.12-.12Z"/>
		<path className={styles.text} d="M201,60.18a.12.12,0,0,1-.12-.12V53.25s0-.07-.08-.07h-2.25a.12.12,0,0,1-.12-.12V52a.12.12,0,0,1,.12-.12h6.14a.12.12,0,0,1,.13.12v1.05a.12.12,0,0,1-.13.12h-2.25c-.05,0-.07,0-.07.07v6.81a.12.12,0,0,1-.13.12Z"/>
		<path className={styles.text} d="M209.4,60.18a.12.12,0,0,1-.12-.12V56.79L206.79,52c0-.06,0-.12.1-.12h1.27a.18.18,0,0,1,.18.12L210,55.23h0L211.75,52a.17.17,0,0,1,.18-.12h1.22c.1,0,.13.06.1.12l-2.48,4.78v3.27a.12.12,0,0,1-.13.12Z"/>
		<path className={styles.text} d="M222.22,58.31A6.37,6.37,0,0,1,222,56a6.44,6.44,0,0,1,.23-2.28,3.3,3.3,0,0,1,6.07,0,6.44,6.44,0,0,1,.23,2.28,6.37,6.37,0,0,1-.23,2.28,3.3,3.3,0,0,1-6.07,0Zm4.64-.37A6.55,6.55,0,0,0,227,56a6.42,6.42,0,0,0-.15-1.9,1.56,1.56,0,0,0-1.61-1.08,1.54,1.54,0,0,0-1.59,1.08,6.42,6.42,0,0,0-.15,1.9,6.55,6.55,0,0,0,.15,1.91A1.54,1.54,0,0,0,225.25,59,1.56,1.56,0,0,0,226.86,57.94Z"/>
		<path className={styles.text} d="M231.43,52a.12.12,0,0,1,.13-.12h5.38a.12.12,0,0,1,.12.12v1.05a.12.12,0,0,1-.12.12H233c-.05,0-.08,0-.08.07v2.11a.07.07,0,0,0,.08.08h3.32c.08,0,.13,0,.13.12v1a.12.12,0,0,1-.13.12H233c-.05,0-.08,0-.08.07v3.26a.12.12,0,0,1-.12.12h-1.24a.12.12,0,0,1-.13-.12Z"/>
		<path className={styles.text} d="M248.15,52a.17.17,0,0,1,.17-.12h1.16a.15.15,0,0,1,.16.12l2.91,8.05c0,.07,0,.12-.09.12h-1.24a.15.15,0,0,1-.16-.12l-.57-1.61h-3.23l-.57,1.61a.16.16,0,0,1-.17.12H245.3c-.09,0-.12,0-.09-.12ZM250,57.17,248.9,54h0l-1.15,3.22Z"/>
		<path className={styles.text} d="M260.12,60.18a.19.19,0,0,1-.19-.12l-1.64-3.23h-1.48c-.05,0-.08,0-.08.07v3.16a.12.12,0,0,1-.12.12h-1.24a.12.12,0,0,1-.13-.12V52a.12.12,0,0,1,.13-.12h3.32a2.52,2.52,0,0,1,2.74,2.47,2.35,2.35,0,0,1-1.63,2.28L261.61,60a.1.1,0,0,1-.09.16ZM260,54.36a1.18,1.18,0,0,0-1.31-1.18h-1.83c-.05,0-.08,0-.08.07v2.21s0,.07.08.07h1.83A1.18,1.18,0,0,0,260,54.36Z"/>
		<path className={styles.text} d="M266.17,60.18a.12.12,0,0,1-.13-.12V53.25s0-.07-.07-.07h-2.25a.12.12,0,0,1-.13-.12V52a.12.12,0,0,1,.13-.12h6.14A.12.12,0,0,1,270,52v1.05a.12.12,0,0,1-.12.12h-2.25c-.05,0-.08,0-.08.07v6.81a.12.12,0,0,1-.12.12Z"/>
		<path className={styles.text} d="M271.86,59.22a.14.14,0,0,1,0-.19l.78-.87a.11.11,0,0,1,.18,0,3.92,3.92,0,0,0,2.34.88c1,0,1.61-.5,1.61-1.19s-.38-1-1.56-1.15l-.46-.06c-1.65-.22-2.59-1-2.59-2.37s1.14-2.5,2.91-2.5a4.84,4.84,0,0,1,2.78.83.11.11,0,0,1,0,.17l-.6.91a.13.13,0,0,1-.18,0,3.71,3.71,0,0,0-2.06-.64c-.87,0-1.35.46-1.35,1.11s.42,1,1.58,1.12l.45.06c1.66.22,2.57,1,2.57,2.4s-1.1,2.57-3.24,2.57A4.93,4.93,0,0,1,271.86,59.22Z"/>
		<path className={styles.text} d="M286.75,56a6.44,6.44,0,0,1,.23-2.28,3,3,0,0,1,3.07-2,3,3,0,0,1,3,1.76.12.12,0,0,1-.05.17l-1.06.45a.12.12,0,0,1-.16-.06,1.84,1.84,0,0,0-1.73-1,1.56,1.56,0,0,0-1.62,1.08,6.42,6.42,0,0,0-.15,1.9,6.55,6.55,0,0,0,.15,1.91A1.56,1.56,0,0,0,290,59a1.62,1.62,0,0,0,1.63-1,3.55,3.55,0,0,0,.13-1.07s0-.07-.07-.07h-1.42a.12.12,0,0,1-.12-.12v-1a.12.12,0,0,1,.12-.12h2.85a.12.12,0,0,1,.12.12v.72a6.63,6.63,0,0,1-.2,1.82,3.32,3.32,0,0,1-6.1,0A6.37,6.37,0,0,1,286.75,56Z"/>
		<path className={styles.text} d="M301.32,60.18a.19.19,0,0,1-.19-.12l-1.64-3.23H298c-.05,0-.08,0-.08.07v3.16a.12.12,0,0,1-.12.12h-1.24a.12.12,0,0,1-.13-.12V52a.12.12,0,0,1,.13-.12h3.32a2.52,2.52,0,0,1,2.74,2.47A2.35,2.35,0,0,1,301,56.64L302.81,60a.1.1,0,0,1-.09.16Zm-.16-5.82a1.19,1.19,0,0,0-1.32-1.18H298c-.05,0-.08,0-.08.07v2.21s0,.07.08.07h1.83A1.18,1.18,0,0,0,301.16,54.36Z"/>
		<path className={styles.text} d="M308,52a.15.15,0,0,1,.16-.12h1.16a.17.17,0,0,1,.17.12l2.9,8.05c0,.07,0,.12-.09.12h-1.24a.16.16,0,0,1-.16-.12l-.57-1.61h-3.23l-.57,1.61a.16.16,0,0,1-.16.12h-1.23c-.09,0-.11,0-.09-.12Zm1.88,5.16L308.75,54h0l-1.15,3.22Z"/>
		<path className={styles.text} d="M315.09,52a.12.12,0,0,1,.13-.12h3.07a2.72,2.72,0,0,1,2.87,1.84,6.86,6.86,0,0,1,.21,2.3,7,7,0,0,1-.21,2.31,2.72,2.72,0,0,1-2.87,1.84h-3.07a.12.12,0,0,1-.13-.12Zm1.57,6.88h1.28a1.63,1.63,0,0,0,1.78-1,5.5,5.5,0,0,0,.15-1.81,5.42,5.42,0,0,0-.15-1.8c-.26-.75-.84-1-1.78-1h-1.28c-.05,0-.08,0-.08.07v5.56A.07.07,0,0,0,316.66,58.89Z"/>
		<path className={styles.text} d="M329.3,57.24V52a.12.12,0,0,1,.12-.12h1.24a.12.12,0,0,1,.13.12v5.26a3.17,3.17,0,0,1-6.33,0V52a.12.12,0,0,1,.12-.12h1.24A.12.12,0,0,1,326,52v5.23a1.68,1.68,0,1,0,3.35,0Z"/>
		<path className={styles.text} d="M335.84,52a.17.17,0,0,1,.17-.12h1.16a.15.15,0,0,1,.16.12l2.91,8.05c0,.07,0,.12-.09.12h-1.24a.15.15,0,0,1-.16-.12l-.57-1.61H335l-.57,1.61a.16.16,0,0,1-.17.12H333c-.09,0-.12,0-.09-.12Zm1.88,5.16L336.59,54h0l-1.15,3.22Z"/>
		<path className={styles.text} d="M344.08,60.18a.12.12,0,0,1-.13-.12V53.25s0-.07-.07-.07h-2.25a.12.12,0,0,1-.13-.12V52a.12.12,0,0,1,.13-.12h6.14a.12.12,0,0,1,.12.12v1.05a.12.12,0,0,1-.12.12h-2.25s-.08,0-.08.07v6.81a.12.12,0,0,1-.12.12Z"/>
		<path className={styles.text} d="M350.5,52a.12.12,0,0,1,.12-.12H356a.12.12,0,0,1,.13.12v1.05a.12.12,0,0,1-.13.12h-3.94c-.05,0-.07,0-.07.07v2s0,.07.07.07h3.33a.12.12,0,0,1,.12.12v1.05a.12.12,0,0,1-.12.12h-3.33c-.05,0-.07,0-.07.08v2.09s0,.08.07.08H356a.12.12,0,0,1,.13.12v1.05a.12.12,0,0,1-.13.12h-5.38a.12.12,0,0,1-.12-.12Z"/>
		<path className={styles.text} d="M365.92,59.22a.14.14,0,0,1,0-.19l.79-.87a.1.1,0,0,1,.17,0,3.92,3.92,0,0,0,2.34.88c1,0,1.62-.5,1.62-1.19s-.38-1-1.57-1.15l-.45-.06c-1.66-.22-2.59-1-2.59-2.37s1.13-2.5,2.9-2.5a4.82,4.82,0,0,1,2.78.83c.06.05.08.1,0,.17l-.6.91a.13.13,0,0,1-.18,0,3.69,3.69,0,0,0-2.06-.64c-.87,0-1.35.46-1.35,1.11s.42,1,1.58,1.12l.46.06c1.65.22,2.56,1,2.56,2.4s-1.1,2.57-3.23,2.57A4.94,4.94,0,0,1,365.92,59.22Z"/>
		<path className={styles.text} d="M375.05,56a6.1,6.1,0,0,1,.23-2.24,2.9,2.9,0,0,1,3-2,3,3,0,0,1,2.91,1.78.11.11,0,0,1-.07.15l-1.07.5a.11.11,0,0,1-.16-.06,1.69,1.69,0,0,0-1.61-1.07,1.51,1.51,0,0,0-1.54,1.09,5.94,5.94,0,0,0-.15,1.89,5.94,5.94,0,0,0,.15,1.89,1.52,1.52,0,0,0,1.54,1.1A1.71,1.71,0,0,0,379.87,58a.11.11,0,0,1,.16-.06l1.07.5a.1.1,0,0,1,.07.14,3,3,0,0,1-2.91,1.78,2.89,2.89,0,0,1-3-2A6.13,6.13,0,0,1,375.05,56Z"/>
		<path className={styles.text} d="M384.09,52a.12.12,0,0,1,.13-.12h1.23a.12.12,0,0,1,.13.12v3.27s0,.07.08.07h3.27c.05,0,.07,0,.07-.07V52a.12.12,0,0,1,.13-.12h1.24a.12.12,0,0,1,.12.12v8.05a.12.12,0,0,1-.12.12h-1.24a.12.12,0,0,1-.13-.12V56.72s0-.08-.07-.08h-3.27c-.06,0-.08,0-.08.08v3.34a.12.12,0,0,1-.13.12h-1.23a.12.12,0,0,1-.13-.12Z"/>
		<path className={styles.text} d="M393.74,58.31a6.37,6.37,0,0,1-.23-2.28,6.44,6.44,0,0,1,.23-2.28,3.31,3.31,0,0,1,6.08,0A6.77,6.77,0,0,1,400,56a6.7,6.7,0,0,1-.22,2.28,3.31,3.31,0,0,1-6.08,0Zm4.64-.37a6.55,6.55,0,0,0,.15-1.91,6.42,6.42,0,0,0-.15-1.9,1.73,1.73,0,0,0-3.2,0A6.42,6.42,0,0,0,395,56a6.55,6.55,0,0,0,.15,1.91,1.73,1.73,0,0,0,3.2,0Z"/>
		<path className={styles.text} d="M403,58.31a6.61,6.61,0,0,1-.23-2.28,6.69,6.69,0,0,1,.23-2.28,3.31,3.31,0,0,1,6.08,0,6.77,6.77,0,0,1,.22,2.28,6.7,6.7,0,0,1-.22,2.28,3.31,3.31,0,0,1-6.08,0Zm4.64-.37a6.55,6.55,0,0,0,.15-1.91,6.42,6.42,0,0,0-.15-1.9,1.73,1.73,0,0,0-3.2,0,6.42,6.42,0,0,0-.15,1.9,6.55,6.55,0,0,0,.15,1.91,1.73,1.73,0,0,0,3.2,0Z"/>
		<path className={styles.text} d="M412.37,52a.12.12,0,0,1,.12-.12h1.24a.12.12,0,0,1,.13.12v6.8s0,.08.07.08h3.94A.12.12,0,0,1,418,59v1.05a.12.12,0,0,1-.13.12h-5.38a.12.12,0,0,1-.12-.12Z"/>
		</g>
	</svg>
)