
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './title.module.scss'


const Titleblock = (props) => (

	<div className={styles.title}>

		<h3 className={styles.text}>{props.text}</h3>

		<p className={styles.more}>
			<NavLink exact to={props.more.path}>{props.more.text}</NavLink>
		</p>

	</div>
)

export default Titleblock