
import 'react-app-polyfill/ie11'

import React from 'react'

import ReactDOM from 'react-dom'

import { Provider } from "react-redux"

import configureStore, { history } from 'states/store.js'

import { ConnectedRouter } from 'connected-react-router'

import App from 'views/app'

import 'styles/common.scss'


const store = configureStore()

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>
, document.getElementById('root'))
