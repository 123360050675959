
import React from 'react'

import $ from 'jquery'

import { Wrap } from 'views/components/common/structure/index'

import styles from './map.module.scss'


class FacilityMap extends React.Component {

	scroll(e) {
		e.preventDefault()

		const id = this.getAnchor(e.target).href.split('#')[1]

		let top = $(`#${id}`).offset().top - document.getElementById('global-header').clientHeight

		if (document.getElementById('page-anchor')) {
			top -= document.getElementById('page-anchor').clientHeight
		}

		$('html,body').stop().animate({scrollTop:top}, 600, 'ioX4')
	}

	getAnchor(el) {
		return el.href ? el : this.getAnchor(el.parentNode)
	}

	render() {
		return (
			<div className={styles.map}>
				<Wrap>
					<div className={styles.image}>
						<img src="/assets/img/facilities/map.jpg" alt="" />
					</div>
					<div className={styles.pins}>
						<ol>
							{this.props.items.filter((item) => item.pin.x && item.pin.y && item.pin.t).map((item, i) => (
							<li key={i} style={{left:`${item.pin.x/1158*100}%`, top:`${item.pin.y/650*100}%`}}>
								<a href={`#facility-${i+1}`} onClick={this.scroll.bind(this)} className={item.pin.t.length > 2 ? styles.mc : ''}>
									<span>{item.pin.t}</span>
								</a>
							</li>
							))}
						</ol>
					</div>
				</Wrap>
			</div>
		)
	}
}

export default FacilityMap
