
import React from 'react'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import styles from './breadcrumb.module.scss'


const Breadcrumb = (props) => {

	if (!props.parents) return null

	return (
		<nav className={styles.breadcrumb}>
			<ol>
				{props.parents && props.parents.map((param, i) => (
					<li key={i}><NavLink exact to={param.path}>{param.name}</NavLink></li>
				))}
				<li>{props.title}</li>
			</ol>
		</nav>
	)
}

function mapStateToProps(state) {
	return {
		title   : state.page.payload['title-ja'],
		parents : state.page.payload ? getParents(state.page.payload.parent) : null
	}
}

function getParents(parent, parents=[]) {
	if (parent) {
		parents.unshift({
			path : parent['path'],
			name : parent['title']
		})
		parents = getParents(parent.parent, parents)
	}
	return parents;
}

export default connect(mapStateToProps)(Breadcrumb)