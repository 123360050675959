
import React from 'react'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import styles from './section.module.scss'


const Section = (props) => (
	<section className={styles.section}>
		<Wrap>
			<Left>
				<h3 className={styles.name}>
					{props['title-ja']}
					{props.posts && (
						<span className={styles.post}>{props.posts[0].name}</span>
					)}
				</h3>
			</Left>
			<Right>

				<div className={styles.biography}>
					<h4>略歴</h4>
					<div dangerouslySetInnerHTML={{__html:props.biography}}></div>
				</div>

				<div className={styles.field}>
					<h4>研究分野</h4>
					<div dangerouslySetInnerHTML={{__html:props.field}}></div>
				</div>

				{props.links && (
				<div className={styles.links}>
					<ul>
						{props.links.map((link, i) => (
						<li key={i}><a href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a></li>
						))}
					</ul>
				</div>
				)}

			</Right>
		</Wrap>
	</section>
)

export default Section
