
import React from 'react'

import { connect } from 'react-redux'



class Gtag extends React.Component {

	componentDidUpdate(prevProps, prevState) {

		if (this.props.path !== prevProps.path) {
			window.gtag('config', 'UA-151200073-1', {'page_path':this.props.path})
		}
	}

	render() {
		return null
	}
}

function mapStateToProps(state) {
	return {
		path : state.router.location.pathname
	}
}

export default connect(mapStateToProps)(Gtag)
