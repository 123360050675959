
import React from 'react'

import withLoading from 'helpers/withLoading'

import Visual from 'views/components/common/visual/index'

import Section from './section'

import Pager from 'views/components/common/pager/index'

import styles from './index.module.scss'


const NewsItem = (props) => (
	<main className={styles.news}>

		<Visual {...props.payload} />

		<Section {...props.payload} />

		<Pager {...props.payload} parent={{path:'/news/', name:'お知らせ'}} />

	</main>
)

export default withLoading(NewsItem)
