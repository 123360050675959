
import React from 'react'

import Polaris from 'lib/Polaris'

import { connect } from 'react-redux'

import deviceActions from 'states/device/actions'

import headerActions from 'states/header/actions'

import styles from './wrapper.module.scss'


class Wrapper extends React.Component {

	constructor(props) {
		super(props);
		this.wrapper = React.createRef();
	}

	componentDidMount() {

		const computedStyle = window.getComputedStyle(this.wrapper.current, '::before');

		let type = null;

		window.scrollBarWidth = computedStyle.getPropertyValue('width');

		Polaris.util.onResize(() => {

			const _type = computedStyle.getPropertyValue('font-family');

			if (type !== _type) {
				type = _type;
				if (type === 'pc') this.props.devicePC();
				if (type === 'tb') this.props.deviceTB();
				if (type === 'sp') this.props.deviceSP();
			}
		})
	}


	render() {
		return (
			<div ref={this.wrapper} className={styles.wrapper} aria-expanded={this.props.showNav}>
				{this.props.children}
			</div>
		)
	}
}


function mapStateToProps(state) {
	return {
		showNav : state.header.visible
	}
}


function mapDispatchToProps(dispatch) {
	return {
		devicePC : () => dispatch(deviceActions.changedPC()),
		deviceTB : () => dispatch(deviceActions.changedTB()),
		deviceSP : () => dispatch(deviceActions.changedSP()),
		hideHeaderNav : () => dispatch(headerActions.hideNav())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
