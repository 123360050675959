
import {
	SEARCH_MODAL_SHOW,
	SEARCH_MODAL_HIDE
} from './types'

const initialState = {
	visible : false
}


export default function searchReducer(state = initialState, action) {

	switch (action.type) {
		case SEARCH_MODAL_SHOW:
			return {...state, visible:true}

		case SEARCH_MODAL_HIDE:
			return {...state, visible:false}

		default:
			return state

	}
}