
import React from 'react'

import Polaris from 'lib/Polaris'

import { connect } from 'react-redux'

import scrollActions from 'states/scroll/actions'

import styles from './html.module.scss'


class Html extends React.Component {

	componentDidMount() {

		let down = null

		Polaris.util.onScroll((t) => {

			const _down = t > 100

			if (down !== _down) {
				down = _down

				if (down) {
					this.props.scrollStateDown()
				} else {
					this.props.scrollStateUp()
				}
			}
		})
	}

	shouldComponentUpdate(nextProps) {

		document.documentElement.setAttribute('lang', nextProps.lang)

		const classNames = [];

		if (nextProps.openModal) {
			classNames.push(styles.openModal)
		}

		if (nextProps.openViewer) {
			classNames.push(styles.openModal)
		}

		if (nextProps.openHeader) {
			classNames.push(styles.openHeader)
		}

		document.documentElement.className = classNames.join(' ')

		return false
	}

	render() {
		return (
			<React.Fragment>
				{this.props.children}
			</React.Fragment>
		)
	}
}

function mapStateToProps(state, props) {
	return {
		lang       : state.page.lang,
		path       : state.page.path,
		openModal  : state.search.visible,
		openViewer : state.viewer.visible,
		openHeader : state.header.visible,
		titleColor : state.page.payload['title-color']
	}
}

function mapDispatchToProps(dispatch) {
	return {
		scrollStateDown : function() {
			dispatch(scrollActions.scrollDown())
		},
		scrollStateUp : function() {
			dispatch(scrollActions.scrollUp())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Html)