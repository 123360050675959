
import React from 'react'

import Title from '../common/title'

import Panel from './panel'

import styles from './index.module.scss'


const Graduationwork = (props) => (
	<section className={styles.graduationwork}>

		<Title text="Graduation works" more={{path:'/graduation-work/', text:'修了作品・論文等について詳しく見る'}} />

		<Panel />

	</section>
)

export default Graduationwork