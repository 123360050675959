
import {
	VIEWER_OPEN,
	VIEWER_CLOSE
} from './types'


export const openViewer = function() {
	return {type:VIEWER_OPEN}
}

export const closeViewer = function() {
	return {type:VIEWER_CLOSE}
}

export default {
	openViewer : openViewer,
	closeViewer : closeViewer
}