
import React from 'react'

import nl2br from 'react-nl2br'

import withLoading from 'helpers/withLoading'

import { Wrap, Left, Right } from '../common/structure/index'

import Visual from 'views/components/common/visual/index'

import Anchor from 'views/components/common/anchor/index'

import Calendar from './calendar'

import styles from './index.module.scss'


const CalendarPage = (props) => (
	<main className={styles.calendar}>

		{/* メインヴィジュアル */}
		<Visual {...props.payload} />

		{/* アンカーリンク */}
		<Anchor {...props.payload} />


		{props.payload.sections && props.payload.sections.map((section, i) => (
		<section className="section" key={i} id={section.anchor ? section.anchor.id : ''}>
			<Wrap>
				<Left>
					<h3>{nl2br(section.title)}</h3>
				</Left>
				<Right>
					<Calendar calendar={section.calendar} />
				</Right>
			</Wrap>
		</section>
		))}
	</main>
)

export default withLoading(CalendarPage)
