
import React from 'react'

import { connect } from 'react-redux'

import { Wrap } from 'views/components/common/structure/index'

import styles from './index.module.scss'


const Visual = (props) => {

	const image = props.device === 'sp' && props['mainimage-sp'] ? props['mainimage-sp'] : props['mainimage-pc']
	const color = props.color ? props.color : props['title-color']

	return (
		<div className={styles.visual} data-color={color}>

			{image ? (
				<div className={styles.hasImage}><img src={image.src} alt={image.alt} /></div>
			) : (
				<div className={styles.noImage}></div>
			)}

			<h2><Wrap>{props['title-en']}</Wrap></h2>
		</div>
	)
}

function mapStateToProps(state) {
	return {
		device : state.device.mode
	}
}

export default connect(mapStateToProps)(Visual)