
import React from 'react'

import Select from 'views/components/common/anchor/select'

import styles from './select.module.scss'


const FacilitySelect = (props) => {

	const sections = props.items.map((item, i) => {
		return {anchor : {id:`facility-${i+1}`, text:item.name}}
	})

	return (
		<div id="page-anchor" className={styles.select}>
			<Select sections={sections} />
		</div>
	)
}

export default FacilitySelect
