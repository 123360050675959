
import {
	DEVICE_CHANGED_PC,
	DEVICE_CHANGED_TB,
	DEVICE_CHANGED_SP
} from './types'

const initialState = {
	mode : 'pc'
}


export default function breakpointReducer(state = initialState, action) {

	switch (action.type) {
		case DEVICE_CHANGED_PC:
			return {...state, mode:'pc'}

		case DEVICE_CHANGED_TB:
			return {...state, mode:'tb'}

		case DEVICE_CHANGED_SP:
			return {...state, mode:'sp'}

		default:
			return state

	}
}