
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/header/actions'

import styles from './language-button.module.scss'


const LanguageButton = (props) => (
	<button className={styles.button} onClick={props.show}>Language</button>
)

function mapStateToProps(state, props) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
		show : () => dispatch(actions.showLanguage())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageButton)