
import React from 'react'

import ReactDOM from 'react-dom'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import { CSSTransition } from 'react-transition-group'

import actions from 'states/header/actions'

import LanguageButton from './sponly/language-button'

import LanguagePanel from './sponly/language-panel'

import SearchButton from './sponly/search-button'

import SearchPanel from './sponly/search-panel'

import styles from './nav-sp.module.scss'


class NavSP extends React.Component {

	componentDidUpdate(nextProps) {
		// URL変更で閉じる
		if (this.props.path !== nextProps.path) {
			this.props.hideNav()
		}
		if (this.props.query !== nextProps.query) {
			this.props.hideNav()
		}
	}

	componentWillUnmount() {
		this.props.hideNav()
	}

	render() {
		const timeout = {
			appear : 20,
			enter  : 700,
			exit   : 700
		}

		return ReactDOM.createPortal((
			<CSSTransition in={this.props.show} timeout={timeout} appear={true} mountOnEnter={true} unmountOnExit={true} classNames={styles}>
				<div className={styles.nav}>
					<div className={styles.closer} onClick={this.props.hideNav}>
						<button><span></span></button>
					</div>
					<div className={styles.navbody}>
						<div className={styles.mainnav}>
							<div className={styles.navheader}>
								<div className={styles.language}>
									<LanguageButton />
								</div>
								<div className={styles.search}>
									<SearchButton />
								</div>
							</div>
							<nav className={styles.gnav}>
								<ul>
									<li><NavLink exact to="/dept/">大学院について</NavLink></li>
									<li><NavLink exact to="/academics/">指導教員</NavLink></li>
									<li><NavLink exact to="/admission/">入試・入学案内</NavLink></li>
									<li><NavLink exact to="/students/">在学生の方へ</NavLink></li>
									<li><NavLink exact to="/graduation-work/">修了作品・論文等</NavLink></li>
									<li><NavLink exact to="/news/">お知らせ</NavLink></li>
									<li><NavLink exact to="/contacts/">お問い合わせ</NavLink></li>
									<li><a href="https://www.osaka-geidai.ac.jp/geidai/request/index.html" target="_blank" rel="noopener noreferrer">資料請求</a></li>
									<li><a href="https://www.osaka-geidai.ac.jp/geidai/guide/access/index.html" target="_blank" rel="noopener noreferrer">交通アクセス</a></li>
								</ul>
							</nav>
						</div>

						<LanguagePanel />

						<SearchPanel />

					</div>
				</div>
			</CSSTransition>
		), document.getElementById('root'))
	}
}

function mapStateToProps(state, props) {
	return {
		show  : state.header.visible,
		path  : state.page.path,
		query : state.page.query
	}
}

function mapDispatchToProps(dispatch) {
	return {
		hideNav : () => dispatch(actions.hideNav())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavSP)