
import React from 'react'

import ReactDOM from 'react-dom'

import { connect } from 'react-redux'


const defaultTitle = "大阪芸術大学 大学院　Osaka University of Arts Graduate School"


const Meta = (props) => {

	const title = props.title ? props.title + ' | ' + defaultTitle : defaultTitle

	return ReactDOM.createPortal((
		<React.Fragment>
			<title>{title}</title>
			<meta name="description" content={props.description} />
			<meta property="og:url" content={window.location.href} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={props.description} />
			<meta property="og:image" content={props.ogImage} />
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content={defaultTitle} />
		</React.Fragment>
	), document.head)
}

function mapStateToProps(state) {
	return {
		title       : state.page.payload['title-ja']    ? state.page.payload['title-ja']        : '',
		ogImage     : state.page.payload['og-image']    ? state.page.payload['og-image']['src'] : '',
		description : state.page.payload['description'] ? state.page.payload['description']     : ''
	}
}

function mapDispatchToProps(dispatch) {
	return {
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Meta)
