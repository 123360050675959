
import React from 'react'

import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import pageActions from 'states/page/actions'

import Error404 from 'views/components/errors/e404/index'

import Error500 from 'views/components/errors/e500/index'

import ErrorElse from 'views/components/errors/else/index'


export default function withLoading(Component) {

	class WithLoadingComponent extends React.Component {

		componentDidMount() {
			this.fetch()
		}

		componentDidUpdate() {
			if (this.props.status === 'complete' && this.props.payload) {
				setTimeout(() => {
					this.props.rendered()
				}, 300)
			}
		}

		fetch() {
			this.props.fetch(
				this.props.router.location.pathname,
				this.props.router.location.search,
				this.props.router.action
			)
		}

		render() {
			if (this.props.error) {
				switch (this.props.error.response.status) {
					case 404:
						return <Error404 />
					case 500:
						return <Error500 />
					default:
						return <ErrorElse />
				}
			} else {
				return <Component {...this.props} />
			}
		}
	}

	function mapStateToProps(state) {
		return {
			router  : state.router,
			device  : state.device.mode,
			status  : state.page.status,
			payload : state.page.payload,
			error   : state.page.error,
		}
	}

	function mapDispatchToProps(dispatch) {
		return {
			fetch : function(path, query, reason) {
				dispatch(pageActions.fetch(path, query, reason))
			},
			rendered : function() {
				dispatch(pageActions.rendered())
			}
		}
	}

	return withRouter(connect(mapStateToProps, mapDispatchToProps)(WithLoadingComponent))
}