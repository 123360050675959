
import React from 'react'

import { withRouter } from 'react-router-dom'

import Polaris from 'lib/Polaris'

import styles from './select.module.scss'


class CategorySelect extends React.Component {

	state = {
		category : 'new'
	}

	componentDidMount() {
		this.setState({
			category : Polaris.util.parseParam().category
		})
	}

	changeCategory(e) {
		this.props.history.push(`/news/?category=${e.target.value}`)
	}

	render() {
		return (
			<div className={styles.select}>
				<label>
					<select onChange={this.changeCategory.bind(this)} value={this.state.category}>
					{this.props.categories.map((category) => (
						<option key={category.slug} value={category.slug}>{category.name}</option>
					))}
					</select>
				</label>
			</div>
		)
	}
}

export default withRouter(CategorySelect)