
import React from 'react'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import { parseLink } from './util'

import styles from './pager.module.scss'


const Link = (props) => {
	if (props.index <= 0 || props.index > props.pages) {
		return (
			<span className={props.className}>
				<span className={styles.outOfRange}>{props.children}</span>
			</span>
		)
	} else {
		return <NavLink exact to={parseLink(props.index)} className={props.className} activeClassName="">{props.children}</NavLink>
	}
}


class NewsPager extends React.Component {

	render() {

		if (!this.props.pager || this.props.pager.pages < 2) return null

		const current = this.props.pager.current
		const index   = current - 1
		const pages   = []

		const i1 = Math.max(index-2, 0)
		const i2 = Math.min(i1+4, this.props.pager.pages-1)
		const i0 = Math.max(0, Math.min(i1, i2-4))

		for (let i=i0; i<=i2; i++) {
			pages.push({text:i+1, index:i+1})
		}

		if (this.props.device !== 'sp' && this.props.pager.pages > 5) {
			if (index > 2) {
				pages.unshift({text:'...', index: null})
				pages.unshift({text:1, index:1})
			} else {
				pages.unshift({text:null, index:null})
				pages.unshift({text:null, index:null})
			}
			if (index < this.props.pager.pages - 3) {
				pages.push({text:'...', index: null})
				pages.push({text:this.props.pager.pages, index:this.props.pager.pages})
			} else {
				pages.push({text:null, index:null})
				pages.push({text:null, index:null})
			}
		}
		
		return (
			<div className={styles.pager}>
				<div className={styles.content}>
					<Link index={current-1} pages={this.props.pager.pages} className={styles.prev}>Previous</Link>

					<ol>
					{pages.map((param, i) => {
						if (param.text !== null) {
							if (param.index !== null) {
								return <li key={i}><Link index={param.index} className={param.index === current ? styles.current : ''}>{param.text}</Link></li>
							} else {
								return <li key={i}><span>{param.text}</span></li>
							}
						} else {
							return <li key={i}></li>
						}
					})}
					</ol>

					<Link index={current+1} pages={this.props.pager.pages} className={styles.next}>Next</Link>
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		device : state.device.mode
	}
}

export default connect(mapStateToProps)(NewsPager)
