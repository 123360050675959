
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/header/actions'

import styles from './menu.module.scss'


const Menu = (props) => (
	<div className={styles.menu}>
		<button className={styles[props.color]} onClick={props.toggleNav}>
			<span></span>
		</button>
	</div>
)

function mapStateToProps(state, props) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
		toggleNav : function() {
			dispatch(actions.toggleNav())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)