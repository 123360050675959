
import React from 'react'

import Polaris from 'lib/Polaris'

import { connect } from 'react-redux'


window.history.scrollRestoration = "manual"


class Scroll extends React.Component {

	componentDidMount() {
		this.scrollTop = 0
		this.scrollMap = new Map()

		Polaris.util.onScroll((t, b) => {
			this.scrollTop = t
		})
	}

	shouldComponentUpdate(nextProps) {

		// パス変化時に前のパスをキーにスクロール量を保存
		if (nextProps.path !== this.props.path) {
			this.scrollMap.set(this.props.path, this.scrollTop)
		}

		// レンダリング完了後、スクロール量を設定
		if (nextProps.rendered !== this.props.rendered && nextProps.rendered) {

			if (nextProps.reason === 'PUSH') {
				window.scrollTo(0, 0)
			}

			if (nextProps.reason === 'POP') {
				window.scrollTo(0, this.scrollMap.get(window.location.pathname))
			}
		}

		return true
	}

	render() {
		return null
	}
}


function mapStateToProps(state) {
	return {
		path     : state.page.path,
		status   : state.page.status,
		reason   : state.page.reason,
		rendered : state.page.rendered
	}
}

export default connect(mapStateToProps)(Scroll)
