
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './contents.module.scss'


const Contents = (props) => (
	<div className={styles.contents}>
		<ul>
			<li>
				<NavLink exact to="/dept/message/">
					<div><img src="/assets/img/top/top_message.jpg" alt="" /></div>
					<dl>
						<dt>Message</dt>
						<dd>学長 塚本邦彦『次代の芸術分野を主導する旗手たる人材の輩出へ』<br /><br />芸術研究科長 長野順子『未来の芸術・アートの冒険者たちへ』</dd>
					</dl>
					<p><span>学長からのメッセージ</span></p>
				</NavLink>
			</li>
			<li>
				<NavLink exact to="/academics/">
					<div><img src="/assets/img/top/top_academics.jpg" alt="" /></div>
					<dl>
						<dt>Academics</dt>
						<dd>本大学院では第一線で活躍する芸術家や研究者が後進の指導にあたっている。</dd>
					</dl>
					<p><span>指導教員について詳しく見る</span></p>
				</NavLink>
			</li>
		</ul>
	</div>
)

export default Contents