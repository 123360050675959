
import Polaris from 'lib/Polaris'

function parseLink(index=1, category=null) {
	const params = []

	if (category) {
		params.push(['category', category])
	}

	if (index !== 1) {
		params.push(['current_page', index])
	}

	const query = params.map((tuple) => tuple[0] + '=' + tuple[1]).join('&')

	if (query) {
		return '/news/?' + query
	} else {
		return '/news/'
	}
}


function isActive(link) {

	const uri = Polaris.util.parseURI(link)

	const query = uri.query.split('&')

	query.forEach((key) => {
	})

	return false
}


export {
	parseLink,
	isActive,
}