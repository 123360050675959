
import * as THREE from 'three'

import Polaris from 'lib/Polaris'

import back_vert from './shaders/back_vert.glsl'
import back_frag from './shaders/back_frag.glsl'


export default class Brain {

	constructor(wrapper) {

		this.wrapper = wrapper

		this.canvasW = 0
		this.canvasH = 0

		this.camera = new THREE.OrthographicCamera(0, 0, 0, 0, 0, 10000)

		this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })

		this.scene = new THREE.Scene()

		this.wrapper.appendChild(this.renderer.domElement)
	}


	resize(w, h) {
		this.canvasW = w
		this.canvasH = h

		this.renderer.setSize(this.canvasW, this.canvasH)

		this.camera.left = this.canvasW / -2
		this.camera.right = this.canvasW / +2
		this.camera.top = this.canvasH / +2
		this.camera.bottom = this.canvasH / -2
		this.camera.updateProjectionMatrix()

		this.radius = Math.sqrt(Math.pow(this.canvasW / 2, 2) + Math.pow(this.canvasH / 2, 2))
	}

	start() {
		new THREE.TextureLoader().load('/assets/img/top/kv1.png', (texture) => {

			const geometry = new THREE.PlaneGeometry()

			const material = new THREE.ShaderMaterial({
				uniforms: {
					texture: { value: texture },
					time: { value: 0 },
					resolution: { value: new THREE.Vector2() }
				},
				vertexShader: back_vert,
				fragmentShader: back_frag,
				blending: THREE.NoBlending,
				depthTest: false,
			})

			this.background = new THREE.Mesh(geometry, material)

			this.scene.add(this.background)

			this.resizeID = Polaris.util.onResize((w, h) => {
				this.resize(w, h)
			})

			this.scrollID = Polaris.util.onScroll((t, b) => {
				this.visible = t <= window.innerHeight
			})

			this.frameID = Polaris.util.onFrame((ct, dt, pt) => {
				if (this.visible) {
					const rad = 0.02 * pt / 1000
					const cos = Math.cos(rad)
					const sin = Math.sin(rad)

					this.background.scale.x = this.canvasW * Math.abs(cos) + this.canvasH * Math.abs(sin)
					this.background.scale.y = this.canvasH * Math.abs(cos) + this.canvasW * Math.abs(sin)
					this.background.rotation.z = rad

					this.background.material.uniforms.time.value = pt / 1000
					this.renderer.render(this.scene, this.camera)
				}
			})
		})
	}

	stop() {
		Polaris.util.offFrame(this.frameID)
	}

	destroy() {
		Polaris.util.offFrame(this.frameID)
		Polaris.util.offResize(this.resizeID)
		Polaris.util.offScroll(this.scrollID)

		this.wrapper.removeChild(this.renderer.domElement)

		this.renderer.dispose()
		this.scene.dispose()
		this.background.geometry.dispose()
		this.background.material.dispose()
	}
}