
import { delay, call, put, fork, all, takeLatest } from 'redux-saga/effects'

import { FETCH_PAGE } from './types'

import actions from './actions'

import fetch from './api'


function* fetchPage(action) {

	yield put(actions.start())

	yield put(actions.progress(0))

	// featch page data
	const load = call(fetch, action.path, action.query)

	// wait loading fadeIn
	const wait = delay(300)

	// dummy progress
	yield fork(dummyProgress, 100, 0.1)
	yield fork(dummyProgress, 200, 0.3)

	// wait for both loading and fadeIn finish
	const { result } = yield all({result:load, wait:wait})

	const { payload, error } = result

	yield put(actions.progress(1))

	if (payload) {
		yield put(actions.success(payload))
	} else {
		yield put(actions.failed(error))
	}
}


function* dummyProgress(msec, progress) {
	yield delay(msec)
	yield put(actions.progress(progress))
}


export default function* pageSaga() {
	yield takeLatest(FETCH_PAGE, fetchPage);
}