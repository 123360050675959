
import React from 'react'

import styles from './lastupdate.module.scss'

const LastUpdate = (props) => {

	if (!props.news) return null

	const dates = props.news.map((item) => {
		return {date:item.date, time:+new Date(item.date)}
	}).sort((d1, d2) => {
		return d1.time > d2.time ? -1 : 1
	})

	return (
		<p className={styles.update}>Last updated on {dates[0].date}</p>
	)
}

export default LastUpdate