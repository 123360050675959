
import React from 'react'

import nl2br from 'react-nl2br'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import Share from './share'

import Slide from 'views/components/common/slide/index'

import styles from './section.module.scss'


const Section = (props) => (
	<section className={styles.section}>
		<Wrap>
			<Left>
				<h3>{nl2br(props.title)}</h3>
				<Share />
			</Left>
			<Right>

				<Slide items={props.images} />

				<div className={styles.content}>
					<div dangerouslySetInnerHTML={{__html:props.content}}></div>
				</div>

				{props.files && (
				<div className={styles.files}>
					<ul>
						{props.files.map((file, i) => (
						<li key={i}><a href={file.url} target="_blank" rel="noopener noreferrer">{file.label}</a></li>
						))}
					</ul>
				</div>
				)}

				{props.links && (
				<div className={styles.links}>
					<ul>
						{props.links.map((link, i) => (
						<li key={i}><a href={link.url} target="_blank" rel="noopener noreferrer">{link.label}</a></li>
						))}
					</ul>
				</div>
				)}

				<Share />
			</Right>
		</Wrap>
	</section>
)

export default Section
