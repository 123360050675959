
export default {
	label : 'Explore Further',

	cols : 3,

	links : [{
		link : '/students/calendar/',
		text : 'キャンパスカレンダー'
	}, {
		link : '/students/course-outline/',
		text : '履修要項'
	}, {
		link : 'http://i.osaka-geidai.ac.jp/syllabus2/select/',
		text : 'シラバス'
	}, {
		link : '/students/documents-mv/',
		text : '前期課程 \n芸術文化学(MV) \n学位（修士）論文申請\n関係様式'
	}, {
		link : '/students/documents-ms/',
		text : '前期課程 \n芸術制作(MS) \n学位（修士）作品申請\n関係様式'
	}, {
		link : '/students/documents-dv/',
		text : '後期課程　\n芸術文化学（DV）\n学位（博士）論文申請\n関係様式'
	}, {
		link : '/students/documents-ds/',
		text : '後期課程　\n芸術制作（DS）\n学位（博士）論文申請\n関係様式'
	}, {
		link : '/students/admin/',
		text : '事務局案内'
	}, {
		link : '/students/support/',
		text : 'サポート体制'
	}, {
		link : 'https://www.osaka-geidai.ac.jp/geidai/campuslife/support/certificate.html',
		text : '各種証明書'
	}, {
		link : 'https://www.osaka-geidai.ac.jp/geidai/campuslife/special_lecture/index.html',
		text : '特別講義'
	}, {
		link : 'https://www.osaka-geidai.ac.jp/geidai/campuslife/international/index.html',
		text : '国際交流'
	}, {
		link : 'https://www.osaka-geidai.ac.jp/geidai/campuslife/support/dormitory/index.html',
		text : '寮・下宿案内'
	}, {
		link : 'https://www.osaka-geidai.ac.jp/geidai/campuslife/career/index.html',
		text : '就職部のご案内'
	}]

}