
export default {
	label : 'Take the next step',

	back : {
		link : '/admission/',
		text : '入試・入学案内 一覧へ戻る'
	},

	cols : 3,

	links : [{
		link : '/admission/admission-policy/',
		text : '入学者選抜方針\n（アドミッションポリシー）'
	}, {
		link : '/admission/master/',
		text : '博士課程（前期）\n芸術制作専攻・\n芸術文化学専攻'
	}, {
		link : '/admission/doctor/',
		text : '博士課程（後期） 芸術専攻'
	}, {
		link : '/admission/foreign/',
		text : '外国人留学生'
	}, {
		link : '/admission/proposal/',
		text : '出願書類一覧'
	}, {
		link : '/admission/expense/',
		text : '学費について'
	}, {
		link : '/admission/scholarship/',
		text : '奨励金・奨学金について'
	}, {
		link : '/admission/event/',
		text : '進学説明会の案内'
	}, {
		link : '/admission/examinee/',
		text : '進学をお考えの方へ'
	}]
}