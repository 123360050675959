
import React from 'react'

import $ from 'jquery'

import { withRouter } from 'react-router-dom'

import styles from './html.module.scss'


class Html extends React.Component {

	constructor(props) {
		super(props)
		this.wrapper = React.createRef()
	}

	componentDidMount() {
		$(this.wrapper.current).on('click', 'a:not([target=_blank])', (e) => {
			e.preventDefault()
			this.props.history.push(e.target.getAttribute('href'))
		})
	}


	componentWillUnmount() {
		$(this.wrapper.current).off('click')
	}

	render() {
		return (
			<div ref={this.wrapper} className={styles.html} dangerouslySetInnerHTML={{__html:this.props.content}}></div>
		)
	}
}

export default withRouter(Html)