
import React from 'react'

import { NavLink } from 'react-router-dom'

import nl2br from 'react-nl2br'

import styles from './button.module.scss'


const Text = (props) => (
	<React.Fragment>
		<p>
			{nl2br(props.text)}

			{props.subtext && (
				<em>{nl2br(props.subtext)}</em>
			)}
		</p>
	</React.Fragment>
)

const Button = (props) => {

	const link = props.link || props.path

	if (link.indexOf('/') === 0 && !link.match(/\.(pdf|xls|doc|ppt|xlsx|docx|pptx)$/)) {
		return (
			<NavLink exact to={link} className={styles.button}>
				<Text {...props} />
				<span></span>
			</NavLink>
		)
	} else {
		return (
			<a href={link} target="_blank" rel="noopener noreferrer" className={styles.button}>
				<Text {...props} />
				<span></span>
			</a>
		)
	}
}

export default Button