
import React from 'react'

import { NavLink } from 'react-router-dom'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import Visual from 'views/components/common/visual/index'

import styles from './index.module.scss'


const Error500 = (props) => (
	<main className={styles.error}>

		{/* メインヴィジュアル */}
		<Visual title-en="System Error" color="black" />

		<section>
			<Wrap>
				<Left></Left>
				<Right>
					<p className={styles.text1}>エラーが発生しました。<br />しばらく時間をおいてから再度お試しください。</p>
					<ul>
						<li><NavLink exact to="/">トップページに戻る</NavLink></li>
					</ul>
				</Right>
			</Wrap>
		</section>
	</main>
)

export default Error500
