
import React from 'react'

import nl2br from 'react-nl2br'

import styles from './download.module.scss'


const Download = (props) => (
	<div className={styles.download}>
		<div>
			<p dangerouslySetInnerHTML={{__html:props.text}} />
			<em>{nl2br(props.subtext)}</em>
		</div>
		<ul>
			{props.links.map((item, i) => (
			<li key={i}><a href={item.path} target="_blank" rel="noopener noreferrer">{item.text}</a></li>
			))}
		</ul>
	</div>
)

export default Download
