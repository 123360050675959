
import React from 'react'

import { withRouter } from 'react-router-dom'

import { CSSTransition } from 'react-transition-group'

import { connect } from 'react-redux'

import actions from 'states/search/actions'

import Form from './form'

import styles from './index.module.scss'


const Search = (props) => (
	<CSSTransition in={props.visible}
		timeout={{appear:20, enter:550, exit:550}}
		appear={true}
		mountOnEnter={true}
		unmountOnExit={true}
		classNames={styles}>

		<div className={styles.search}>
			<div>
				<button className={styles.logo} onClick={props.hideModal}>
					<img src="/assets/img/common/logo_color.svg" alt="" />
				</button>

				<button className={styles.close} onClick={props.hideModal}>
					<span></span>
				</button>


				<Form afterSubmit={props.hideModal} />
			</div>
		</div>
	</CSSTransition>
)

function mapStateToProps(state, props) {
	return {
		visible : state.search.visible
	}
}

function mapDispatchToProps(dispatch) {
	return {
		hideModal : () => dispatch(actions.hideModal())
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search))