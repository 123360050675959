
import React from 'react'

import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'


class Form extends React.Component {

	input = React.createRef()

	state = {
		query : ''
	}

	onChange(e) {
		this.setState({query:e.target.value})
	}

	onSubmit(e) {
		e.preventDefault()

		if (this.state.query) {
			this.props.history.push(`/search/?query=${this.state.query}`)
			this.props.afterSubmit()
		}
	}

	componentDidMount() {
		if (this.props.device !== 'sp') {
			this.input.current.focus()
		}
	}

	render() {
		return (
			<form onSubmit={this.onSubmit.bind(this)}>
				<input type="text" name="q" value={this.state.query} onChange={this.onChange.bind(this)} placeholder="Search" ref={this.input} />
				<input type="submit" value="" />
			</form>
		)
	}
}

function mapStateToProps(state) {
	return {
		device : state.device.mode
	}
}

export default withRouter(connect(mapStateToProps)(Form))