
import {
	HEADER_NAV_SHOW,
	HEADER_NAV_HIDE,
	HEADER_NAV_TOGGLE,
	HEADER_SEARCH_SHOW,
	HEADER_SEARCH_HIDE,
	HEADER_LANGUAGE_SHOW,
	HEADER_LANGUAGE_HIDE
} from './types'


export const showNav = function() {
	return {type:HEADER_NAV_SHOW}
}

export const hideNav = function() {
	return {type:HEADER_NAV_HIDE}
}

export const toggleNav = function() {
	return {type:HEADER_NAV_TOGGLE}
}

export const showSearch = function() {
	return {type:HEADER_SEARCH_SHOW}
}

export const hideSearch = function() {
	return {type:HEADER_SEARCH_HIDE}
}

export const showLanguage = function() {
	return {type:HEADER_LANGUAGE_SHOW}
}

export const hideLanguage = function() {
	return {type:HEADER_LANGUAGE_HIDE}
}

export default {
	showNav   : showNav,
	hideNav   : hideNav,
	toggleNav : toggleNav,
	showSearch : showSearch,
	hideSearch : hideSearch,
	showLanguage : showLanguage,
	hideLanguage : hideLanguage
}