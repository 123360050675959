
import {
	SEARCH_MODAL_SHOW,
	SEARCH_MODAL_HIDE
} from './types'


export const showModal = function() {
	return {type:SEARCH_MODAL_SHOW}
}

export const hideModal = function() {
	return {type:SEARCH_MODAL_HIDE}
}

export default {
	showModal : showModal,
	hideModal : hideModal
}