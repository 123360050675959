
import React from 'react'

import { NavLink } from 'react-router-dom'

import Title from '../common/title'

import Panel from './panel'

import styles from './index.module.scss'


const Admission = (props) => (
	<section className={styles.admission}>

		<Title text="Admission" more={{path:'/admission/', text:'入学案内について詳しく見る'}} />

		<Panel />

		<div className={styles.morewrap}>
			<p className={styles.more}>
				<NavLink exact to="/admission/">入学案内について詳しく見る</NavLink>
			</p>
		</div>

	</section>
)

export default Admission