
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './panel.module.scss'


const Panel = (props) => (
	<div className={styles.panel}>
		<div>
			<h4>博士課程（前期）</h4>
			<ul>
				<li>
					<NavLink exact to="/admission/master/master-general/">
						<p>一般選抜入学試験</p>
						<span></span>
					</NavLink>
				</li>
				<li>
					<NavLink exact to="/admission/master/master-adult/">
						<p>社会人特別選抜入学試験</p>
						<span></span>
					</NavLink>
				</li>
			</ul>
		</div>
		<div>
			<h4>博士課程（後期）</h4>
			<ul>
				<li>
					<NavLink exact to="/admission/doctor/doctor-general/">
						<p>一般選抜入学試験</p>
						<span></span>
					</NavLink>
				</li>
				<li>
					<NavLink exact to="/admission/doctor/doctor-adult/">
						<p>社会人特別選抜入学試験</p>
						<span></span>
					</NavLink>
				</li>
			</ul>
		</div>
	</div>
)

export default Panel