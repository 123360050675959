
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/viewer/actions'

import Button from './button'

import Modal from './modal'

import styles from './index.module.scss'


const Viewer = (props) => (
	<div className={styles.viewer}>
		<Button {...props} open={props.open} />
		<Modal />
	</div>
)

function mapStateToProps(state) {
	return {}
}

function mapDispatchToProps(dispatch) {
	return {
		open : () => dispatch(actions.openViewer())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Viewer)
