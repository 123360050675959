
import React from 'react';

import { NavLink } from 'react-router-dom'

import styles from './fnav.module.scss';


const Fnav = (props) => (
	<div className={styles.fnav}>
		<nav>
			<ul>
				<li><NavLink exact to="/news/">お知らせ</NavLink></li>
				<li><NavLink exact to="/contacts/">お問い合わせ</NavLink></li>
				<li><NavLink exact to="/sitemap/">サイトマップ</NavLink></li>
				<li><a href="https://www.osaka-geidai.ac.jp/geidai/guide/access/index.html" target="_blank" rel="noopener noreferrer">交通アクセス</a></li>
				<li><a href="https://www.osaka-geidai.ac.jp/geidai/request/index.html" target="_blank" rel="noopener noreferrer">資料請求</a></li>
			</ul>
		</nav>
	</div>
)

export default Fnav