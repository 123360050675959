
import React from 'react'

import withLoading from 'helpers/withLoading'

import Visual from 'views/components/common/visual/index'

import Section from './section'

import Pager from 'views/components/common/pager/index'

import Related from 'views/components/common/related/index'

import styles from './index.module.scss'


const ProfessorItem = (props) => (
	<main className={styles.professor}>

		<Visual {...props.payload} />

		<Section {...props.payload} />

		{props.payload.further && (
			<Pager {...props.payload} parent={{path:`/academics/${props.payload.further.slug}/`, name:props.payload.further.name}} />
		)}

		<Related {...props.payload} />

	</main>
)

export default withLoading(ProfessorItem)
