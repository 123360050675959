
import React from 'react'

import Slider from './slider'

import Select from './select'

import styles from './category.module.scss'


const Category = (props) => {

	if (!props.category) return null

	return (
		<div id="page-anchor" className={styles.category}>

			<div className={styles.sliderWrap}>
				<Slider categories={props.category} />
			</div>

			<div className={styles.selectWrap}>
				<Select categories={props.category} />
			</div>
			
		</div>
	)
}

export default Category