
import {
	VIEWER_OPEN,
	VIEWER_CLOSE
} from './types'


const initialState = {
	visible : false,
}


export default function viewerReducer(state = initialState, action) {

	switch (action.type) {
		case VIEWER_OPEN:
			return {...state, visible:true}

		case VIEWER_CLOSE:
			return {...state, visible:false}

		default:
			return state

	}
}