
import {
	DEVICE_CHANGED_PC,
	DEVICE_CHANGED_TB,
	DEVICE_CHANGED_SP
} from './types'


export const changedPC = function() {
	return {type:DEVICE_CHANGED_PC}
}

export const changedTB = function() {
	return {type:DEVICE_CHANGED_TB}
}

export const changedSP = function() {
	return {type:DEVICE_CHANGED_SP}
}

export default {
	changedPC : changedPC,
	changedTB : changedTB,
	changedSP : changedSP
}