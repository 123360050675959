
import React from 'react'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import styles from './slider.module.scss'


class AnchorSlider extends React.Component {

	ready = false

	slider = React.createRef()

	mover = React.createRef()

	ol = React.createRef()

	state = {
		prevActive : false,
		nextActive : false
	}


	set moverX(val) {
		this._x = val

		if (this.limitX < 0) {
			if (this.ol.current) this.ol.current.style.transform = `translate3d(${this.moverX}px, 0, 0)`

			this.setState({
				prevActive : this.moverX < 0,
				nextActive : this.moverX > this.limitX
			})
		} else {
			if (this.ol.current) this.ol.current.style.transform = ``

			this.setState({
				prevActive : false,
				nextActive : false
			})
		}
	}

	get moverX() {
		return this._x || 0
	}

	componentDidMount() {
		this.start()
	}

	componentDidUpdate(prevProps) {
		this.start()

		if (this.props.sections) {
			setTimeout(() => {
				this.resized()
			})
		}
	}

	componentWillUnmount() {
		Polaris.util.offFrame(this.frameID)
		Polaris.util.offResize(this.resizeID)
		$(this.slider.current).off('*')
	}

	start() {
		if (this.slider.current && !this.ready) {
			this.ready = true
			this.init()
		}
	}

	init() {

		let tx = null, ty, dx, dy, scroll

		this.resizeID = Polaris.util.onResize(() => {
			this.resized()
		})

		$(this.slider.current).on('click', 'a', (e) => {
			e.preventDefault()

			if (!this.dragging) {
				const id = e.target.getAttribute('href').split('#')[1]

				if (id.match(/^[a-zA-Z\-_0-9]+$/)) {
					let top = $(`#${id}`).offset().top - document.getElementById('global-header').clientHeight

					if (document.getElementById('page-anchor')) {
						top -= document.getElementById('page-anchor').clientHeight
					}

					$('html,body').stop().animate({scrollTop:top}, 600, 'ioX4')
				}
			}
		})

		$(this.slider.current).on({

			'touchstart mousedown' : (e) => {
				if (this.limitX < 0) {
					Polaris.util.offFrame(this.frameID)
					tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
					ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY
					dx = 0
					dy = 0
					scroll = null

					$(this.slider.current).css({cursor:'grabbing'})
				}
			},

			'touchmove mousemove' : (e) => {
				if (tx !== null) {
					const _tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
					const _ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY

					dx = _tx - tx;
					dy = _ty - ty;
					tx = _tx;
					ty = _ty;

					if (scroll === null) {
						scroll = Math.abs(dy) > Math.abs(dx)

						if (scroll) {
							tx = null
							ty = null
						} else {
							e.preventDefault()
							this.moverX += dx
							this.dragging = true
						}
					} else {
						e.preventDefault()
						this.moverX += dx
					}
				}
			},

			'touchend touchcancel mouseup mouseleave' : (e) => {
				if (tx !== null) {
					this.frameID = Polaris.util.onFrame((ct, dt, pt) => {

						const timeScale = Math.min(dt, 50) / (1000 / 60)

						let outBounds = false

						this.moverX += (dx *= Math.min(0.99, 0.95 * timeScale))

						if (this.moverX > 0) {
							outBounds = true
							this.moverX += (0 - this.moverX) * 0.2 * timeScale
						}

						if (this.moverX < this.limitX) {
							outBounds = true
							this.moverX += (this.limitX - this.moverX) * 0.2 * timeScale
						}

						if (!outBounds && Math.abs(dx) < 0.01) return false
					})

					setTimeout(() => {
						this.dragging = false
					})

					tx = null
					ty = null
				}

				$(this.slider.current).css({cursor:''})
			}
		})
	}

	resized() {
		if (this.mover.current) {

			this.width = [].reduce.call(this.ol.current.getElementsByTagName('li'), (w, li, i) => {
				return w + $(li).outerWidth(true)
			}, 0)

			this.limitX = Math.min(0, this.mover.current.clientWidth - this.width);

			if (this.limitX < 0) {
				$(this.ol.current).css({width:this.width}).addClass('draggable')
			} else {
				$(this.ol.current).css({width:''}).removeClass('draggable')
			}

			this.moverX = this.moverX
		}
	}

	prev() {
		this.change(150)
	}

	next() {
		this.change(-150)
	}

	change(dx) {
		Polaris.util.offFrame(this.frameID)

		const x = Polaris.util.clamp(this.moverX + dx, this.limitX, 0)

		$(this).stop().animate({moverX:x}, 300, 'ioX4')
	}

	render() {
		return (
			<div className={styles.slider} ref={this.slider}>
				<button className={styles.prev} onClick={this.prev.bind(this)} aria-disabled={!this.state.prevActive}></button>
				<button className={styles.next} onClick={this.next.bind(this)} aria-disabled={!this.state.nextActive}></button>
				<div className={styles.mover} ref={this.mover}>
					<ol ref={this.ol}>
					{this.props.sections.filter((section) => section.anchor).map((section) => (
						<li key={section.anchor.id}>
							<a href={'#'+section.anchor.id}>{section.anchor.text}</a>
						</li>
					))}
					</ol>
				</div>
			</div>
		)
	}
}

export default AnchorSlider