
import React from 'react'

import $ from 'jquery'

import styles from './select.module.scss'


class AnchorSelect extends React.Component {

	state = {
		anchor : ''
	}

	scroll(e) {
		const id = e.target.value

		if (id.match(/^[a-zA-Z\-_0-9]+$/)) {
			let top = $(`#${id}`).offset().top - document.getElementById('global-header').clientHeight

			if (document.getElementById('page-anchor')) {
				top -= document.getElementById('page-anchor').clientHeight
			}

			this.setState({
				anchor : id
			})

			$('html,body').stop().animate({scrollTop:top}, 600, 'ioX4')
		}
	}

	render() {
		return (
			<div className={styles.select}>
				<label>
					<select onChange={this.scroll.bind(this)} value={this.state.anchor}>
					{this.props.sections.filter((section) => section.anchor).map((section) => (
						<option key={section.anchor.id} value={section.anchor.id}>{section.anchor.text}</option>
					))}
					</select>
				</label>
			</div>
		)
	}
}

export default AnchorSelect