
export default `

vec4 blend(vec4 src, vec4 dst) {
	float a = src.w + dst.w * (1.0 - src.w);
	float r = (src.x * src.w + dst.x * dst.w * (1.0 - src.w) ) / a;
	float g = (src.y * src.w + dst.y * dst.w * (1.0 - src.w) ) / a;
	float b = (src.z * src.w + dst.z * dst.w * (1.0 - src.w) ) / a;
	return vec4(r, g, b, a);
}

vec3 hsv2rgb(float h, float s, float v) {
	float r = v;
	float g = v;
	float b = v;

	if (s > 0.0) {
		h *= 6.0;

		int i = int(h);

		float f = h - float(i);

		if (i == 0) {
			g *= 1.0 - s * (1.0 - f);
			b *= 1.0 - s;
		} else if (i == 1) {
			r *= 1.0 - s * f;
			b *= 1.0 - s;
		} else if (i == 2) {
			r *= 1.0 - s;
			b *= 1.0 - s * (1.0 - f);
		} else if (i == 3) {
			r *= 1.0 - s;
			g *= 1.0 - s * f;
		} else if (i == 4) {
			r *= 1.0 - s * (1.0 - f);
			g *= 1.0 - s;
		} else {
			g *= 1.0 - s;
			b *= 1.0 - s * f;
		}
	}

	return vec3(r, g, b);
}
`