
export const HEADER_NAV_SHOW = "HEADER_NAV_SHOW"

export const HEADER_NAV_HIDE = "HEADER_NAV_HIDE"

export const HEADER_NAV_TOGGLE = "HEADER_NAV_TOGGLE"

export const HEADER_SEARCH_SHOW = "HEADER_SEARCH_SHOW"

export const HEADER_SEARCH_HIDE = "HEADER_SEARCH_HIDE"

export const HEADER_LANGUAGE_SHOW = "HEADER_LANGUAGE_SHOW"

export const HEADER_LANGUAGE_HIDE = "HEADER_LANGUAGE_HIDE"