
import React from 'react'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import styles from './section.module.scss'


const Section = (props) => (
	<div className={styles.section}>
		<Wrap>
			<Left>
				<h3 className={styles.name}>
					{props['title-ja']}
					{props.post && (
						<span className={styles.post}>{props.post}</span>
					)}
				</h3>
			</Left>
			<Right>
				{props.sections && props.sections.map((section, i) => (
				<section key={i}>
					{section.title && (
					<h4 className={styles.title}>
						{section.title}
					</h4>
					)}

					{section.text && (
					<div className={styles.text}>
						<div dangerouslySetInnerHTML={{__html:section.text}}></div>
					</div>
					)}
				</section>
				))}
			</Right>
		</Wrap>
	</div>
)

export default Section
