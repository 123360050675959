
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './panel.module.scss'


const Panel = (props) => (
	<div className={styles.panel}>
		<ul>
			<li>
				<NavLink exact to="/graduation-work/doctoral-dissertation/">
					<div>
						<img src="/assets/img/top/top_doctoral-dissertation.jpg" alt="" />
						<span></span>
					</div>
					<p>博士論文</p>
				</NavLink>
			</li>
			<li>
				<NavLink exact to="/graduation-work/master-thesis/">
					<div>
						<img src="/assets/img/top/top_master-thesis.jpg" alt="" />
						<span></span>
					</div>
					<p>修士論文</p>
				</NavLink>
			</li>
			<li>
				<NavLink exact to="/graduation-work/graduation-work/">
					<div>
						<img src="/assets/img/top/top_graduation-work.jpg" alt="" />
						<span></span>
					</div>
					<p>修了作品</p>
				</NavLink>
			</li>
			<li>
				<NavLink exact to="/graduation-work/bulletin-paper/">
					<div>
						<img src="/assets/img/top/top_bulletin-paper.jpg" alt="" />
						<span></span>
					</div>
					<p>紀要論文</p>
				</NavLink>
			</li>
		</ul>
	</div>
)

export default Panel