
import React from 'react'

import $ from 'jquery'

import { Wrap } from 'views/components/common/structure/index'

// import TypeA from 'lib/type-a/index'
// import TypeB from 'lib/type-b/index'
// import TypeC from 'lib/type-c/index'
// import TypeD from 'lib/type-d/index'
// import TypeE from 'lib/type-e/index'
// import TypeF from 'lib/type-f/index'
// import TypeG from 'lib/type-g/index'
// import TypeH from 'lib/type-h/index'
// import TypeI from 'lib/type-i/index'
// import TypeJ from 'lib/type-j/index'
import TypeK from 'lib/type-k/index'

import Control from './control'

import styles from './index.module.scss'


class Stage extends React.Component {

	wrapper = React.createRef()

	componentDidMount() {
		this.brain = new TypeK(this.wrapper.current)
		this.brain.start()
	}

	// __componentDidMount() {

	// 	this.type = window.location.search

	// 	if (this.type === '?a') {
	// 		this.brain = new TypeA(this.wrapper.current)

	// 	} else if (this.type === '?b') {
	// 		this.brain = new TypeB(this.wrapper.current)

	// 	} else if (this.type === '?c') {
	// 		this.brain = new TypeC(this.wrapper.current)

	// 	} else if (this.type === '?d') {
	// 		this.brain = new TypeD(this.wrapper.current)
			
	// 	} else if (this.type === '?e') {
	// 		this.brain = new TypeE(this.wrapper.current)

	// 	} else if (this.type === '?f') {
	// 		this.brain = new TypeF(this.wrapper.current)
		
	// 	} else if (this.type === '?g') {
	// 		this.brain = new TypeG(this.wrapper.current)

	// 	} else if (this.type === '?h') {
	// 		this.brain = new TypeH(this.wrapper.current)

	// 	} else if (this.type === '?i') {
	// 		this.brain = new TypeI(this.wrapper.current)
		
	// 	} else if (this.type === '?j') {
	// 		this.brain = new TypeJ(this.wrapper.current)
		
	// 	} else if (this.type === '?k') {
	// 		this.brain = new TypeK(this.wrapper.current)

	// 	} else {
	// 		//this.brain = new TypeX(this.wrapper.current)
	// 	}
		
	// 	if (this.brain) {
	// 		this.brain.start()
	// 	}
	// }

	componentWillUnmount() {
		if (this.brain) {
			this.brain.destroy()
		}
	}

	scroll() {
		$('html,body').stop().animate({scrollTop:window.innerHeight}, 800, 'ioX4')
	}

	render() {
		return (
			<div className={styles.stage}>
				<div ref={this.wrapper}></div>

				<div className={styles.message}>
					<Wrap>
						<p>We seek new ideas and help people stay creative.</p>
					</Wrap>
				</div>

				<div className={styles.scroll}>
					<Wrap>
						<button onClick={this.scroll.bind(this)}><span></span></button>
					</Wrap>
				</div>

				{this.type === '?c' && <Control brain={this.brain} />}
			</div>
		)
	}
}

export default Stage