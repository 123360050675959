
import React from 'react'

import { NavLink } from 'react-router-dom'

import { parseLink } from './util'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import styles from './slider.module.scss'


const CategoryLink = (props) => {

	const query = Polaris.util.parseParam()

	if (query.category === props.type || (!query.category && props.type === 'new')) {
		return <NavLink to={parseLink()} activeClassName={styles.active}>{props.children}</NavLink>
	} else {
		return <NavLink to={parseLink(1, props.type)}>{props.children}</NavLink>
	}
}

class CategorySlider extends React.Component {

	ready = false

	slider = React.createRef()

	mover = React.createRef()

	ol = React.createRef()


	set moverX(val) {
		this._x = val

		if (this.ol.current) {
			this.ol.current.style.transform = `translate3d(${this.moverX}px, 0, 0)`
		}
	}

	get moverX() {
		return this._x || 0
	}

	componentDidMount() {
		this.start()
	}

	componentDidUpdate(prevProps) {
		this.start()

		setTimeout(() => {
			this.resized()
		})
	}

	componentWillUnmount() {
		Polaris.util.offFrame(this.frameID)
		Polaris.util.offResize(this.resizeID)
		$(this.slider.current).off('*')
	}

	start() {
		if (this.slider.current && !this.ready) {
			this.ready = true
			this.init()
		}
	}

	init() {

		let tx = null, ty, dx, dy, scroll

		this.resizeID = Polaris.util.onResize(() => {
			this.resized()
		})

		$(this.slider.current).on('click', 'a', (e) => {
			if (this.dragging) {
				e.preventDefault()
			}
		})

		$(this.slider.current).on({

			'touchstart mousedown' : (e) => {
				Polaris.util.offFrame(this.frameID)
				tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
				ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY
				dx = 0
				dy = 0
				scroll = null
			},

			'touchmove mousemove' : (e) => {
				if (tx !== null) {
					const _tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
					const _ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY

					dx = _tx - tx;
					dy = _ty - ty;
					tx = _tx;
					ty = _ty;

					if (scroll === null) {
						scroll = Math.abs(dy) > Math.abs(dx)

						if (scroll) {
							tx = null
							ty = null
						} else {
							e.preventDefault()
							this.moverX += dx
							this.dragging = true
						}
					} else {
						e.preventDefault()
						this.moverX += dx
					}
				}
			},

			'touchend touchcancel mouseup mouseleave' : (e) => {
				if (tx !== null) {
					this.frameID = Polaris.util.onFrame((ct, dt, pt) => {

						const timeScale = Math.min(dt, 50) / (1000 / 60)

						let outBounds = false

						this.moverX += (dx *= Math.min(0.99, 0.95 * timeScale))

						if (this.moverX > 0) {
							outBounds = true
							this.moverX += (0 - this.moverX) * 0.2 * timeScale
						}

						if (this.moverX < this.limitX) {
							outBounds = true
							this.moverX += (this.limitX - this.moverX) * 0.2 * timeScale
						}

						if (!outBounds && Math.abs(dx) < 0.01) return false
					})

					setTimeout(() => {
						this.dragging = false
					})

					tx = null
					ty = null
				}
			}
		})
	}

	resized() {
		if (this.mover.current) {

			this.width = [].reduce.call(this.ol.current.getElementsByTagName('li'), (w, li, i) => {
				return w + $(li).outerWidth(true)
			}, 0)

			$(this.ol.current).css({width:this.width})

			this.limitX = Math.min(0, this.mover.current.clientWidth - this.width);
		}
	}

	render() {
		return (
			<div className={styles.slider} ref={this.slider}>
				<div className={styles.mover} ref={this.mover}>
					<ol ref={this.ol}>
					{this.props.categories.map((item) => (
					<li key={item.slug}>
						<CategoryLink type={item.slug}>{item.name}</CategoryLink>
					</li>
					))}
					</ol>
				</div>
			</div>
		)
	}
}

export default CategorySlider