
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './item.module.scss'


const Item = (props) => (
	<NavLink exact to={props.path} className={styles.item}>
		<div className={styles.thumbnail}>
			{props.thumbnail.src ? (
				<img src={props.thumbnail.src} alt={props.thumbnail.alt} />
			) : (
				<img src="/assets/img/common/default_news_thumbnail.jpg" alt="" />
			)}
		</div>
		<div className={styles.data}>
			<dl>
				<dt>{props.category.map((c) => c.name).join(', ')}</dt>
				<dd>{props.title}</dd>
			</dl>
			<div className={styles.date}>{props.date}</div>
		</div>
	</NavLink>
)

export default Item