
import React from 'react'

import withLoading from 'helpers/withLoading'

import Visual from 'views/components/common/visual/index'

import Pager from 'views/components/common/pager/index'

import Related from 'views/components/common/related/index'

import Section from './section'

import styles from './index.module.scss'


const MessageItem = (props) => (
	<main className={styles.message}>

		<Visual {...props.payload} />

		<Section {...props.payload} />

		<Pager {...props.payload} parent={{path:'/dept/graduate/', name:'社会で活躍する修了生'}} />

		<Related {...props.payload} />

	</main>
)

export default withLoading(MessageItem)
