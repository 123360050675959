
import React from 'react'

import { connect } from 'react-redux'

import actions from 'states/search/actions'

import styles from './button.module.scss'


class Button extends React.Component {

	showModal() {
		this.props.showModal()
	}

	render() {
		return (
			<div className={styles.button}>
				<button className={styles[this.props.color]} onClick={this.showModal.bind(this)}></button>
			</div>
		)
	}
}

function mapStateToProps(state, props) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
		showModal : function() {
			dispatch(actions.showModal())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Button)