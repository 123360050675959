
import React from 'react'

import Html     from 'views/containers/html'
import Wrapper  from 'views/containers/wrapper'
import Content  from 'views/containers/content'
import Scroll   from 'views/containers/scroll'

import Gtag     from 'views/components/common/gtag/index'
import Meta     from 'views/components/common/meta/index'
import Loading  from 'views/components/common/loading/index'
import Header   from 'views/components/common/header/index'
import Footer   from 'views/components/common/footer/index'
import Search   from 'views/components/common/search/index'


const App = (props) => (
	<Html>
		<Gtag />
		<Meta />
		<Scroll />

		<Wrapper>
			<Loading />
			<Header />
			<Content />
			<Footer />
			<Search />
		</Wrapper>
	</Html>
)

export default App
