
import React from 'react'

import styles from './index.module.scss'


const Wrap = (props) => (
	<div className={styles.wrap}>
		{props.children}
	</div>
)

const Left = (props) => (
	<div className={styles.left}>
		{props.children}
	</div>
)

const Right = (props) => (
	<div className={styles.right}>
		{props.children}
	</div>
)

export {
	Wrap,
	Left,
	Right,
}