
import React from 'react'

import { NavLink } from 'react-router-dom'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import styles from './section.module.scss'


const Section = (props) => (
	<section className={styles.section}>
		<Wrap>
			<Left>
				<h3>教員からのメッセージ</h3>
			</Left>
			<Right>
				<ul>
				{props.messages && props.messages.map((item, i) => (
				<li key={i}>
					<NavLink exact to={item.path}>
						<div><img src={item.thumbnail.src} alt={item.thumbnail.alt} /></div>
						<dl>
							<dt>{item.name}</dt>
							<dd>{item.post}</dd>
						</dl>
					</NavLink>
				</li>
				))}
				</ul>
			</Right>
		</Wrap>
	</section>
)

export default Section
