
import React from 'react'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import styles from './index.module.scss'


class Language extends React.Component {

	state = {
		open : false
	}

	componentDidUpdate(nextProps) {
		// URL変更で閉じる
		if (this.props.path !== nextProps.path) {
			this.closeAccordion()
		}
		if (this.props.query !== nextProps.query) {
			this.closeAccordion()
		}
	}

	toggleAccordion() {
		this.setState({
			open : !this.state.open
		})
	}

	closeAccordion() {
		this.setState({
			open : false
		})
	}

	render() {
		return (
			<div className={styles.lang}>

				<button className={styles[this.props.color]} onClick={this.toggleAccordion.bind(this)}>Language<span></span></button>

				<div className={styles.accordion} aria-expanded={this.state.open}>
					<ul className={styles[this.props.color]}>
						<li><NavLink className={this.props.lang === 'ja' ? styles.current : ''} exact to="/">日本語</NavLink></li>
						<li><NavLink className={this.props.lang === 'en' ? styles.current : ''} exact to="/en-us/">English</NavLink></li>
					</ul>
				</div>

			</div>
		)
	}
}

function mapStateToProps(state, props) {
	return {
		lang  : state.page.lang,
		path  : state.page.path,
		query : state.page.query
	}
}


export default connect(mapStateToProps)(Language)