
import React from 'react'

import { CSSTransition } from 'react-transition-group'

import { connect } from 'react-redux'

import actions from 'states/viewer/actions'

import Slide from './slide/index'

import styles from './modal.module.scss'


const animation = {
	enterActive  : styles.enterActive,
	enterDone    : styles.enterDone,
	exitActive   : styles.exitActive,
}

class Modal extends React.Component {

	render() {
		return (
			<CSSTransition in={this.props.visible} timeout={500} mountOnEnter={true} unmountOnExit={true} classNames={animation}>
				<div className={styles.modal}>
					<Slide />

					<button onClick={this.props.close} className={styles.logo}>
						<img src="/assets/img/common/logo_color.svg" alt="" />
					</button>

					<button onClick={this.props.close} className={styles.close}>
						<span>×</span>
					</button>
				</div>
			</CSSTransition>
		)
	}
}

function mapStateToProps(state) {
	return {
		visible : state.viewer.visible,
	}
}

function mapDispatchToProps(dispatch) {
	return {
		close : () => dispatch(actions.closeViewer())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)