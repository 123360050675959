
import React from 'react'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import Buttons from 'views/components/common/buttons/list'

import styles from './section.module.scss'


const Section = (props) => (
	<section className={styles.section} id={props.anchor ? props.anchor.id : ''}>
		<Wrap>
			<Left>
				<h3>{props.title}</h3>
			</Left>
			<Right>
				<Buttons cols={3} buttons={props.list} />
			</Right>
		</Wrap>
	</section>
)

export default Section