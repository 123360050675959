
import React from 'react'

import { NavLink } from 'react-router-dom'

import SearchButton from 'views/components/common/search/button'

import Language from 'views/components/common/language/index'

import styles from './nav-pc.module.scss'


const NavPC = (props) => (
	<div className={styles.nav}>
		<nav className={styles.snav}>
			<div className={styles.search}>
				<SearchButton color={props.color} />
			</div>
			<ul>
				<li><NavLink className={styles[props.color]} exact to="/news/">お知らせ</NavLink></li>
				<li><NavLink className={styles[props.color]} exact to="/contacts/">お問い合わせ</NavLink></li>
				<li><a className={styles[props.color]} href="https://www.osaka-geidai.ac.jp/geidai/request/index.html" target="_blank" rel="noopener noreferrer">資料請求</a></li>
				<li><a className={styles[props.color]} href="https://www.osaka-geidai.ac.jp/geidai/guide/access/index.html" target="_blank" rel="noopener noreferrer">交通アクセス</a></li>
			</ul>
			<div className={styles.language}>
				<Language color={props.color} />
			</div>
		</nav>
		<nav className={styles.gnav}>
			<ul>
				<li><NavLink className={styles[props.color]} exact to="/dept/">大学院について</NavLink></li>
				<li><NavLink className={styles[props.color]} exact to="/academics/">指導教員</NavLink></li>
				<li><NavLink className={styles[props.color]} exact to="/admission/">入試・入学案内</NavLink></li>
				<li><NavLink className={styles[props.color]} exact to="/students/">在学生の方へ</NavLink></li>
				<li><NavLink className={styles[props.color]} exact to="/graduation-work/">修了作品・論文等</NavLink></li>
			</ul>
		</nav>
	</div>
)

export default NavPC