
import React from 'react'

import styles from './share.module.scss'


class ShareButton extends React.Component {

	open(e) {
		e.preventDefault();
		const winW = 600;
		const winH = 300;
		const winX = window.screen.availWidth / 2 - winW / 2;
		const winY = window.screen.availHeight / 2 - winH / 2;
		const url  = this.props.base + encodeURIComponent(window.location.href)

		window.open(url, 'share', `menubar=no,location=yes,status=no,width=${winW},height=${winH},top=${winY},left=${winX}`);
	}

	render() {
		return <button onClick={this.open.bind(this)}></button>
	}
}

const Share = () => (
	<div className={styles.share}>
		<dl>
			<dt>SHARE</dt>
			<dd>
				<ul>
					<li className={styles.facebook}><ShareButton base="http://www.facebook.com/sharer.php?u=" /></li>
					<li className={styles.twitter}><ShareButton base="http://twitter.com/share?url=" /></li>
					<li className={styles.line}><ShareButton base="https://social-plugins.line.me/lineit/share?url=" /></li>
				</ul>
			</dd>
		</dl>
	</div>
)

export default Share
