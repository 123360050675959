
import { combineReducers } from 'redux'

import { connectRouter } from 'connected-react-router'

import htmlReducer from './html/sagas'

import pageReducer from './page/reducers'

import scrollReducer from './scroll/reducers'

import searchReducer from './search/reducers'

import deviceReducer from './device/reducers'

import headerReducer from './header/reducers'

import viewerReducer from './viewer/reducers'


const createRootReducer = (history) => combineReducers({
	 router : connectRouter(history)
	,html   : htmlReducer
	,page   : pageReducer
	,scroll : scrollReducer
	,search : searchReducer
	,device : deviceReducer
	,header : headerReducer
	,viewer : viewerReducer
})


export default createRootReducer