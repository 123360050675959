
import React from 'react'

import { connect } from 'react-redux'

import { NavLink } from 'react-router-dom'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import actions from 'states/search/actions'

import Visual from 'views/components/common/visual/index'

import styles from './index.module.scss'


const Error404 = (props) => (
	<main className={styles.error}>

		{/* メインヴィジュアル */}
		<Visual title-en="404 - Not found" color="black" />

		<section>
			<Wrap>
				<Left></Left>
				<Right>
					<p className={styles.text1}>お探しのページは一時的にアクセスできない状態か、移動もしくは削除された可能性があります。<br />大変お手数をおかけしますが、次の方法で、お求めの情報をお探しください。</p>
					<ul>
						<li><NavLink exact to="/">トップページに戻る</NavLink></li>
						<li><NavLink exact to="/sitemap/">サイトマップを見る</NavLink></li>
						<li><button onClick={props.showModal}>ページ上部にあるサイト内検索で検索してみる</button></li>
					</ul>
				</Right>
			</Wrap>
		</section>
	</main>
)

function mapStateToProps(state, props) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return {
		showModal : function() {
			dispatch(actions.showModal())
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Error404)
