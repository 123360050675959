import React from 'react'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Controller from './controller'

import styles from './index.module.scss'


const animation = {
    enterDone: styles.enterDone,
    enterActive: styles.enterActive,
    exitActive: styles.exitActive
}

const timeout = {
    appear: 0,
    enter: 500,
    exit: 500
}

class Slide extends React.Component {

    PAGE_NUM = 337

    scale = 1.0
    
    slide = React.createRef()

    content = React.createRef()

    scaler = React.createRef()

    state = {
        current: 0,
        image: 1,
        pages: [],
        grabbed : false
    }

    componentDidMount() {
        this.change(0)

        // ズーム時のドラッグ
        let sx, sy, tx = null, ty = null
    
        $(this.content.current).on({
            'mousedown': (e) => {
                if (this.scale > 1) {
                    sx = this.content.current.scrollLeft
                    sy = this.content.current.scrollTop
                    tx = e.pageX
                    ty = e.pageY

                    this.setState({
                        grabbed: true
                    })
                }
            },
            'mousemove': (e) => {
                if (tx !== null) {
                    const cx = sx - (e.pageX - tx)
                    const cy = sy - (e.pageY - ty)
                    const cw = this.content.current.clientWidth
                    const ch = this.content.current.clientHeight
                    this.updateScale(this.scale, cx / cw / (this.scale - 1), cy / ch / (this.scale - 1))
                }
            },
            'mouseup mouseleave': (e) => {
                tx = null
                ty = null

                this.setState({
                    grabbed: false
                })
            }
        })


        // スマホの拡縮
        let cx, cy, distance, iniScale = null

        $(this.content.current).on({
            'touchstart': (e) => {
                if (e.touches.length > 1) {
                    e.preventDefault()
                    iniScale = this.scale
                    distance = Math.sqrt(Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2))
                    cx = (e.touches[0].pageX + e.touches[1].pageX) / 2
                    cy = (e.touches[0].pageY + e.touches[1].pageY) / 2
                }
            },
            'touchmove': (e) => {
                if (iniScale !== null) {
                    e.preventDefault()
                    const _d = Math.sqrt(Math.pow(e.touches[0].pageX - e.touches[1].pageX, 2) + Math.pow(e.touches[0].pageY - e.touches[1].pageY, 2))
                    const cw = this.content.current.clientWidth * this.scale
                    const ch = this.content.current.clientHeight * this.scale
                    this.updateScale(iniScale * _d / distance, cx / cw, cy / ch)
                }
            },
            'touchend touchcancel': (e) => {
                iniScale = null
            }
        })
    }

    zoom(arg) {
        const scale = Polaris.util.clamp(this.scale + arg * 0.5, 1.0, 3.0)

        if (scale !== this.scale) {
            const sx = this.content.current.scrollLeft
            const sy = this.content.current.scrollTop
            const cw = this.content.current.clientWidth
            const ch = this.content.current.clientHeight

            const cx = (cw * 0.5 + sx) / (cw * this.scale)
            const cy = sy > 0 ? (ch * 0.5 + sy) / (ch * this.scale) : 0

            this.updateScale(scale, cx, cy)
        }
    }

    scrollBy(arg) {
        this.content.current.scrollTop += arg;
    }

    change(index) {
        this.setState({
            current: Math.max(0, Math.min(index, this.PAGE_NUM - 1)),
        })

        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
            // 上端中央にスクロール
            this.updateScale(this.scale, 0.5, 0)

            this.setState({
                image: this.state.current + 1
            })
        }, 50)
    }

    updateScale(scale, cx, cy) {

        this.scale = Polaris.util.clamp(scale, 1.0, 3.0)

        const cw = this.content.current.clientWidth
        const ch = this.content.current.clientHeight

        this.scaler.current.style.width  = this.scale * 100 + '%'
        this.scaler.current.style.height = this.scale * 100 + '%'
        this.content.current.scrollTop   = ch * cy * (this.scale - 1)
        this.content.current.scrollLeft  = cw * cx * (this.scale - 1)
    }

    render() {
        return (
            <div className={styles.slide} ref={this.slide}>
                <div className={styles.content} ref={this.content} aria-disabled={this.scale === 1} aria-grabbed={this.state.grabbed}>
                    <div className={styles.scaler} ref={this.scaler}>
                        <TransitionGroup appear={true} enter={true} exit={true} component={null}>
                            <CSSTransition key={this.state.image} timeout={timeout} classNames={animation}>
                                <div className={styles.image} style={{ backgroundImage: `url(/assets/img/viewer/takamado/takamado_Part${this.state.image}.jpg)` }}></div>
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>

                <Controller
                    pages={this.PAGE_NUM}
                    current={this.state.current}
                    change={this.change.bind(this)}
                    zoom={this.zoom.bind(this)}
                    scrollBy={this.scrollBy.bind(this)}
                />
            </div>
        )
    }
}

export default Slide