
import React from 'react'

import nl2br from 'react-nl2br'

import styles from './button.module.scss'


const Button = (props) => (
	<div className={styles.button}>
		<div>
			<p dangerouslySetInnerHTML={{__html:props.text}} />
			<em>{nl2br(props.subtext)}</em>
		</div>
		<ul>
			{props.links.map((item, i) => {
				if (item.path) {
					return <li key={i}><a href={item.path} target="_blank" rel="noopener noreferrer">{item.text}</a></li>
				} else {
					return <li key={i}><button onClick={props.open}>{item.text}</button></li>
				}
			})}
		</ul>
	</div>
)

export default Button
