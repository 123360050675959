
import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './titleblock.module.scss'


const Titleblock = (props) => (
	<div className={styles.titleblock}>
		<h3 className={styles.titletext}>Outline</h3>
		<p className={styles.titlelead}>本大学院は、国際的に通用する芸術理論の構築を目指す「芸術文化学」と確かな理論的裏付けのもと自らの芸術性を追求する「芸術制作」の二部門で構成されている。それにより専攻・領域を横断した学際的研究に取り組むにふさわしい環境が整備されている。</p>
		<p className={styles.morelink}><NavLink exact to="/dept/">大学院について詳しく見る</NavLink></p>
	</div>
)

export default Titleblock