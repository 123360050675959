
import React from 'react'

import styles from './dates.module.scss'

const SingleDate = (props) => (
	<React.Fragment>
		<span className={styles.date}>{props.date}</span>
		<span className={styles.weekday}>({props.weekday})</span>
	</React.Fragment>
)

const Dates = (props) => (
	<React.Fragment>
	{props.dates.map((date, i) => {
		return (
			<div key={i} className={styles.datewrap}>
				<SingleDate {...date[0]} />

				{date.length > 1 && (
					<React.Fragment>
					<span className={styles.range}>～</span>
					<SingleDate {...date[1]} />
					</React.Fragment>
				)}
			</div>
		)
	})}
	</React.Fragment>
)

export default Dates
