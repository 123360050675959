
import React from 'react'

import withLoading from 'helpers/withLoading'

import Visual from '../common/visual/index'

import Anchor from '../common/anchor/index'

import Related from '../common/related/index'

import Sections from './sections/index'

import styles from './index.module.scss'


const Page = (props) => {

	if (!props.payload || !props.payload.sections) return null

	return (
		<main className={styles.page}>

			{/* メインヴィジュアル */}
			<Visual {...props.payload} />

			{/* アンカーリンク */}
			<Anchor {...props.payload} />

			{/* 繰り返しセクション */}
			<Sections {...props.payload} />

			{/* 関連投稿 */}
			<Related {...props.payload} />
		</main>
	)
}

export default withLoading(Page)

