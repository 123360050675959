
import React from 'react'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import Item from './item'

import styles from './slide.module.scss'


class Slide extends React.Component {

	state = {
		prevActive : false,
		nextActive : true
	}

	constructor(props) {
		super(props)

		this.mover = React.createRef()

		this.ul = React.createRef()
	}

	set moverX(val) {
		this._x = val

		if (this.ul.current) {
			this.ul.current.style.transform = `translate3d(${this.moverX}px, 0, 0)`
		}

		const prevActive = this._x < 0
		const nextActive = this._x > this.limitX

		if (prevActive !== this.state.prevActive) this.setState({prevActive:prevActive})
		if (nextActive !== this.state.nextActive) this.setState({nextActive:nextActive})
	}

	get moverX() {
		return this._x || 0
	}

	componentDidMount() {
		this.init()
	}

	componentDidUpdate(prevProps) {
		if (this.props.news) {
			setTimeout(() => {
				this.resized()
			})
		}
	}

	componentWillUnmount() {
		Polaris.util.offFrame(this.frameID)
		Polaris.util.offResize(this.resizeID)
		$(this.ul.current).off('*')
	}

	resized() {
		if (this.mover.current) {

			this.width = [].reduce.call(this.ul.current.getElementsByTagName('li'), (w, li, i) => {
				return w + $(li).outerWidth(true)
			}, 0)

			this.limitX = Math.min(0, this.mover.current.clientWidth - this.width);
		}
	}

	init() {
		let tx = null, ty, dx, dy, scroll

		this.resizeID = Polaris.util.onResize(() => {
			this.resized()
		})

		$(this.ul.current).on('click', 'a', (e) => {
			if (this.dragging) e.preventDefault()
		})

		$(this.ul.current).on({

			'touchstart mousedown' : (e) => {
				Polaris.util.offFrame(this.frameID)
				tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
				ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY
				dx = 0
				dy = 0
				scroll = null
			},

			'touchmove mousemove' : (e) => {
				if (tx !== null) {
					const _tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
					const _ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY

					dx = _tx - tx;
					dy = _ty - ty;
					tx = _tx;
					ty = _ty;

					if (scroll === null) {
						scroll = Math.abs(dy) > Math.abs(dx)

						if (scroll) {
							tx = null
							ty = null
						} else {
							e.preventDefault()
							this.moverX += dx
							this.dragging = true
						}
					} else {
						e.preventDefault()
						this.moverX += dx
					}
				}
			},

			'touchend touchcancel mouseup mouseleave' : (e) => {
				if (tx !== null) {
					this.frameID = Polaris.util.onFrame((ct, dt, pt) => {

						const timeScale = Math.min(dt, 50) / (1000 / 60)

						let outBounds = false

						this.moverX += (dx *= Math.min(0.99, 0.95 * timeScale))

						if (this.moverX > 0) {
							outBounds = true
							this.moverX += (0 - this.moverX) * 0.2 * timeScale
						}

						if (this.moverX < this.limitX) {
							outBounds = true
							this.moverX += (this.limitX - this.moverX) * 0.2 * timeScale
						}

						if (!outBounds && Math.abs(dx) < 0.01) return false
					})

					setTimeout(() => {
						this.dragging = false
					})

					tx = null
					ty = null
				}
			}
		})
	}

	prev(e) {
		this.change(-1);
	}

	next (e) {
		this.change(+1);
	}

	change(step) {

		Polaris.util.offFrame(this.frameID)

		const $li = $(this.ul.current).find('li').eq(0)

		const x = Polaris.util.clamp(this.moverX - step * $li.outerWidth(true), this.limitX, 0)

		$(this).stop().animate({moverX:x}, 450, 'ioX4')
	}

	render() {
		return (
			<div className={styles.slide}>
				<div className={styles.slideWrapper}>
					<div className={styles.slideMover} ref={this.mover}>
						<ul ref={this.ul}>
						{this.props.news && this.props.news.map((item, i) => (
							<li key={i}><Item {...item} /></li>
						))}
						</ul>
					</div>
				</div>
				<nav className={styles.slideNav}>
					<ul>
						<li><button onClick={this.prev.bind(this)} aria-disabled={!this.state.prevActive}></button></li>
						<li><button onClick={this.next.bind(this)} aria-disabled={!this.state.nextActive}></button></li>
					</ul>
				</nav>
			</div>
		)
	}
}

export default Slide