
import React from 'react';

import Breadcrumb from './breadcrumb'

import Pagetop from './pagetop'

import styles from './ftop.module.scss';


const Ftop = (props) => (
	<div className={styles.ftop}>
		<div>
			<Breadcrumb />
			<Pagetop />
		</div>
	</div>
)

export default Ftop