
import React from 'react'

import $ from 'jquery'

import Polaris from 'lib/Polaris'

import styles from './index.module.scss'


class Slide extends React.Component {

	state = {
		current    : 0,
		prevActive : true,
		nextActive : true
	}

	constructor(props) {
		super(props)

		this.mover = React.createRef()

		this.initialized = false
	}

	set moverX(x) {
		this._x = x;

		if (this.mover.current) {
			this.mover.current.style.transform = `translate3d(${this._x}px, 0, 0)`
		}
	}

	get moverX() {
		return this._x || 0;
	}

	componentDidUpdate() {
		if (this.mover.current && !this.initialized) {
			this.init()
		}
	}

	init() {
		this.initialized = true

		let tx, ty, dx, dy, flick, start

		this.slideNum = this.mover.current.getElementsByTagName('li').length

		this.resizeID = Polaris.util.onResize(() => {
			this.resize()
		})

		$(this.mover.current).on({

			'mousedown touchstart' : (e) => {
				Polaris.util.offFrame(this.frameID);
				tx = e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX
				ty = e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY
				dx = 0
				dy = 0
				flick = null
				start = this.moverX
			},

			'mousemove touchmove' : (e) => {
				if (start !== null && flick !== false) {
					dx = (e.originalEvent.touches ? e.originalEvent.touches[0].clientX : e.clientX) - tx
					dy = (e.originalEvent.touches ? e.originalEvent.touches[0].clientY : e.clientY) - ty

					if (flick === null) {
						flick = Math.abs(dx) >= Math.abs(dy)

						if (flick) {
							e.preventDefault()
						} else {
							this.change(this.state.current)
							start = null
						}
					} else {
						if (flick) {
							e.preventDefault()
							this.moverX = start + dx
						}
					}
				}
			},

			'mouseleave mouseup touchend touchcancel' : (e) => {
				if (start !== null) {
					if (Math.abs(dx) > 50) {
						if (this.state.current === 0) {
							if (dx > 0) {
								this.change(this.state.current)
							} else {
								this.next()
							}
						} else if (this.state.current === this.slideNum - 1) {
							if (dx > 0) {
								this.prev()
							} else {
								this.change(this.state.current)
							}
						} else {
							if (dx > 0) {
								this.prev()
							} else {
								this.next()
							}
						}
					} else {
						this.change(this.state.current);
					}
					start = null;
				}
			}
		})
	}

	componentWillUnmount() {
		Polaris.util.offFrame(this.frameID)
		Polaris.util.offResize(this.resizeID)
		$(this.mover.current).off('*')
	}

	prev(e) {
		this.change(this.state.current - 1);
	}

	next (e) {
		this.change(this.state.current + 1);
	}

	resize() {
		this.change(this.state.current, true);
	}

	change(index, atOnce=false) {
		Polaris.util.offFrame(this.frameID);

		index = Polaris.util.clamp(index, 0, this.slideNum-1)

		if (atOnce) {
			this.animate(index, 0)
		} else {
			this.animate(index, 700)
		}
	}

	animate(index, duration) {
		const x0 = this.moverX;
		const x1 = -1 * this.mover.current.clientWidth * index

		this.setState({
			current    : index,
			prevActive : index > 0,
			nextActive : index < this.slideNum -1
		})

		this.frameID = Polaris.util.onFrame((ct, dt, pt) => {

			const p = duration > 0 ? Polaris.util.clamp(pt / duration, 0, 1) : 1

			this.moverX = x0 + (x1 - x0) * Polaris.easing.ioX4(p)

			if (p === 1) return false
		});
	}

	zeroPad(n) {
		return ('0' + n).slice(-2)
	}

	render() {

		if (!this.props.items) return null

		if (this.props.items.length === 1) {
			return (
				<div className={styles.single}>
					<img src={this.props.items[0].src} alt={this.props.items[0].alt} />
				</div>
			)
		} else {
			return (
				<div className={styles.slide}>
					<div className={styles.slideWrapper}>
						<div className={styles.slideMover} ref={this.mover}>
							<ul style={{width:this.props.items.length*100+'%'}}>
								{this.props.items && this.props.items.map((image, i) => (
								<li key={i}>
									<div><img src={image.src} alt={image.alt} /></div>
								</li>
								))}
							</ul>
						</div>
						<button className={styles.prev} onClick={this.prev.bind(this)} aria-disabled={!this.state.prevActive} />
						<button className={styles.next} onClick={this.next.bind(this)} aria-disabled={!this.state.nextActive} />
					</div>
					<nav className={styles.slideNav}>
						<div className={styles.slideIndex}>
							<em>{this.zeroPad(this.state.current + 1)}</em> / <span>{this.zeroPad(this.props.items.length)}</span>
						</div>
						<div className={styles.slideBar}>
							<div style={{width:(this.state.current+1) / this.props.items.length*100+'%'}}></div>
						</div>
						<div className={styles.slidePager}>
							<ul>
								<li className={styles.prev}><button onClick={this.prev.bind(this)} aria-disabled={!this.state.prevActive} ></button></li>
								<li className={styles.next}><button onClick={this.next.bind(this)} aria-disabled={!this.state.nextActive} ></button></li>
							</ul>
						</div>
					</nav>
				</div>
			)
		}
	}
}

export default Slide
