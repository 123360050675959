
import React from 'react'

import nl2br from 'react-nl2br'

import { Wrap, Left, Right } from 'views/components/common/structure/index'

import styles from './items.module.scss'


const FacilityItems = (props) => (
	<div className={styles.items}>
		{props.items.map((facility, i) => (
		<section key={i} id={`facility-${i+1}`}>
			<Wrap>
				<Left>
					<h3>{nl2br(facility.name)}</h3>
				</Left>
				<Right>
					<div className={styles.flex}>
						<div className={styles.text} dangerouslySetInnerHTML={{__html:facility.text}}>
						</div>
						<div className={styles.image}>
							<img src={facility.image.src} alt={facility.image.alt} />
						</div>
					</div>
				</Right>
			</Wrap>
		</section>
		))}
	</div>
)

export default FacilityItems
