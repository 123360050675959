
import React from 'react'

import { NavLink } from 'react-router-dom'

import withLoading from 'helpers/withLoading'

import Visual from '../common/visual/index'

import Pager from './pager'

import styles from './index.module.scss'


const Search = (props) => (
	<main className={styles.search}>

		<Visual {...props.payload} />

		<ul>
		{props.payload.results && props.payload.results.map((item, i) => (
			<li key={i}>
				{item.type !== 'ファイル' ? (
					<NavLink exact to={item.path}>
						<div className={styles.image}><img src={item.image.src} alt={item.image.alt} /></div>
						<div className={styles.data}>
							<span>{item.type}</span>
							<div>{item.title}</div>
							<p><em>詳しく見る</em></p>
						</div>
					</NavLink>
				) : (
					<a href={item.path} target="_blank" rel="noopener noreferrer">
						<div className={styles.image}><img src={item.image.src} alt={item.image.alt} /></div>
						<div className={styles.data}>
							<span>{item.type}</span>
							<div>{item.title}</div>
							<p><em>開く</em></p>
						</div>
					</a>
				)}
			</li>
		))}
		</ul>

		<Pager {...props.payload} />

	</main>
)

export default withLoading(Search)
