
import React from 'react'

import { Switch, Route, withRouter } from 'react-router-dom'

import { TransitionGroup, CSSTransition } from 'react-transition-group'

import styles from './content.module.scss'

import Home          from 'views/components/home/index'
import Page          from 'views/components/page/index'
import MessageList   from 'views/components/message/list/index'
import MessageItem   from 'views/components/message/item/index'
import GraduateList  from 'views/components/graduate/list/index'
import GraduateItem  from 'views/components/graduate/item/index'
import ProfessorList from 'views/components/professor/list/index'
import ProfessorItem from 'views/components/professor/item/index'
import NewsList      from 'views/components/news/list/index'
import NewsItem      from 'views/components/news/item/index'
import FacilityList  from 'views/components/facility/list/index'
import Calendar      from 'views/components/calendar/index'
import Search        from 'views/components/search/index'
import mixPageLink   from 'views/components/common/pagelink/index'

// 回遊リンクパラメータ
import deptTop       from 'data/links_dept_top'
import deptSub       from 'data/links_dept_sub'

import academicsTop  from 'data/links_academics_top'
import academicsSub  from 'data/links_academics_sub'

import admissionTop  from 'data/links_admission_top'
import admissionSub  from 'data/links_admission_sub'

import graduationTop from 'data/links_graduation_top'
import graduationSub from 'data/links_graduation_sub'

import studentsTop   from 'data/links_students_top'
import studentsSub   from 'data/links_students_sub'


const timeout = {
	appear : 0,
	enter  : 300,
	exit   : 300
}

const Content = ({ location }) => (
	<TransitionGroup component={null} appear={false} enter={true} exit={true}>
		<CSSTransition key={location.key} timeout={timeout} classNames={{...styles}}>
			<Switch location={location}>
				{/* トップ */}
				<Route exact component={Home} path="/" />

				{/* 大学院案内 */}
				<Route exact component={mixPageLink(Page, deptTop)} path="/dept/" />
				<Route exact component={mixPageLink(Page, deptSub)} path="/dept/message/" />
				<Route exact component={mixPageLink(Page, deptSub)} path="/dept/curriculum/" />
				<Route exact component={mixPageLink(Page, deptSub)} path="/dept/diploma/" />
				<Route exact component={mixPageLink(Page, deptSub)} path="/dept/org-faculty/" />

				{/* 施設一覧 */}
				<Route exact component={mixPageLink(FacilityList, deptSub)} path="/dept/facilities/" />

				{/* 教員からのメッセージ */}
				<Route exact component={mixPageLink(MessageList, deptSub)} path="/dept/teacher-message/" />
				<Route exact component={mixPageLink(MessageItem, deptSub)} path="/dept/teacher-message/:slug" />

				{/* 社会で活躍する修了生 */}
				<Route exact component={mixPageLink(GraduateList, deptSub)} path="/dept/graduate/" />
				<Route exact component={mixPageLink(GraduateItem, deptSub)} path="/dept/graduate/:slug" />

				{/* 指導教員 */}
				<Route exact component={mixPageLink(Page, academicsTop)} path="/academics/" />
				<Route exact component={mixPageLink(ProfessorList, academicsSub)} path="/academics/arts/" />
				<Route exact component={mixPageLink(ProfessorList, academicsSub)} path="/academics/creative-arts/" />
				<Route exact component={mixPageLink(ProfessorList, academicsSub)} path="/academics/common/" />
				<Route exact component={mixPageLink(ProfessorItem, academicsSub)} path="/academics/arts/:slug" />
				<Route exact component={mixPageLink(ProfessorItem, academicsSub)} path="/academics/creative-arts/:slug" />
				<Route exact component={mixPageLink(ProfessorItem, academicsSub)} path="/academics/common/:slug" />

				{/* 入試・入学案内 */}
				<Route exact component={mixPageLink(Page, admissionTop)} path="/admission/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/admission-policy/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/master/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/master/master-general/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/master/master-adult/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/doctor/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/doctor/doctor-general/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/doctor/doctor-adult/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/foreign/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/proposal/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/expense/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/scholarship/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/event/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/faq/" />
				<Route exact component={mixPageLink(Page, admissionSub)} path="/admission/examinee/" />

				{/* 在学生の方へ */}
				<Route exact component={mixPageLink(Page, studentsTop)} path="/students/" />
				<Route exact component={mixPageLink(Calendar, studentsSub)} path="/students/calendar/" />
				<Route exact component={mixPageLink(Page, studentsSub)} path="/students/course-outline/" />
				<Route exact component={mixPageLink(Page, studentsSub)} path="/students/documents-mv/" />
				<Route exact component={mixPageLink(Page, studentsSub)} path="/students/documents-ms/" />
				<Route exact component={mixPageLink(Page, studentsSub)} path="/students/documents-dv/" />
				<Route exact component={mixPageLink(Page, studentsSub)} path="/students/documents-ds/" />
				<Route exact component={mixPageLink(Page, studentsSub)} path="/students/admin/" />
				<Route exact component={mixPageLink(Page, studentsSub)} path="/students/support/" />

				{/* 修了作品・論文等 */}
				<Route exact component={mixPageLink(Page, graduationTop)} path="/graduation-work/" />
				<Route exact component={mixPageLink(Page, graduationSub)} path="/graduation-work/doctoral-dissertation/" />
				<Route exact component={mixPageLink(Page, graduationSub)} path="/graduation-work/master-thesis/" />
				<Route exact component={mixPageLink(Page, graduationSub)} path="/graduation-work/graduation-work/" />
				<Route exact component={mixPageLink(Page, graduationSub)} path="/graduation-work/bulletin-paper/" />

				{/* お知らせ */}
				<Route exact component={NewsList} path="/news/" />
				<Route exact component={NewsItem} path="/news/:slug" />

				{/* その他 */}
				<Route exact component={Search} path="/search/" />
				<Route exact component={Page} path="/en-us/" />
				<Route exact component={Page} path="/zh-cn/" />
				<Route exact component={Page} path="/zh-tw/" />
				<Route exact component={Page} path="/ko-kr/" />
				<Route exact component={Page} path="/contacts/" />
				<Route exact component={Page} path="/sitemap/"/>
				<Route exact component={Page} path="/policy/" />

				{/* 404 */}
				<Route component={Page} />
				
			</Switch>
		</CSSTransition>
	</TransitionGroup>
)

export default withRouter(Content)
