
export default {
	label : 'Take the next step',

	back : {
		link : '/graduation-work/',
		text : '修了作品・論文等 一覧へ戻る'
	},

	cols : 2,

	links : [{
		link : '/graduation-work/doctoral-dissertation/',
		text : '博士論文'
	}, {
		link : '/graduation-work/master-thesis/',
		text : '修士論文'
	}, {
		link : '/graduation-work/graduation-work/',
		text : '修了作品（選抜）'
	}, {
		link : '/graduation-work/bulletin-paper/',
		text : '紀要論文'
	}]
}