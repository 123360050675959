
import React from 'react';

import Ftop from './ftop'

import Fbottom from './fbottom'

import styles from './index.module.scss';


const Footer = (props) => (
	<footer className={styles.footer}>
		<Ftop />
		<Fbottom />
	</footer>
)

export default Footer